<template>
    <div class="home-logo-btn" @click="$router.push({ name: 'Home'})"></div>
</template>

<script>
export default {
    name: 'LogoHome',
}
</script>

<style scoped lang="scss">
    @import '@/assets/scss/components/components/header/logo.scss';
</style>
