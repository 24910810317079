<template>
    <li :class="['main-menu__item', 'main-menu__item--' + id, { 'active': isActive }]" @click="handleClick">
        <span :class="['icon', 'icon--' + id]"></span>
        <span class="item--text">
            <span v-if="userTokens !== undefined" class="token-count">{{ userTokens }}<br/></span>
            {{ title }}
        </span>
        <span v-if="userTokens !== undefined" class="icon icon--add"></span>
    </li>
</template>

<script>
export default {
    name: 'MenuItem',
    props: {
        title: {
            type: String,
            required: true
        },
        id: {
            type: String,
            required: true
        },
        path: {
            type: String,
            required: true
        },
        userTokens: {
            type: Number,
            default: undefined
        },
        isActive: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        handleClick() {
            this.$emit('menu-clicked', this.id);
            this.$router.push({ path: this.path });
        }
    }
}
</script>
