<template>
    <div class="main">
        <HeaderComponent @toggle-menu="toggleMenu" />
        <div class="body">
            <LeftBar :isOpen="isMenuOpen" @close-menu="closeMenu"/>
            <div class="content">
                <RouterView />
                <FooterComponent />
            </div>
            <MobileBottomMenu/>
        </div>
        <FullPageModal
            v-if="showFullPageModal && messagesLength >= 10 && !isSubscribed"
            :show="showFullPageModal"
            :bannerComponent="UpdateSubscriptionBanner"
            :closeHandler="handleCloseFullPageModal"
        />
        <ModalFrame v-if="showModalFrame" :closeAndRedirect="closeAndRedirect" />
        <ModalFrameRoutin v-if="showModalFrameRoutin" />
    </div>
</template>

<script>
import { markRaw } from 'vue';
import { mapGetters } from 'vuex';
import LeftBar from '@/components/global/LeftBar.vue';
import HeaderComponent from '@/components/global/HeaderComponent.vue';
import FooterComponent from '@/components/global/FooterComponent.vue';
import FullPageModal from '@/components/components/modals/FullPageModal.vue';
import UpdateSubscriptionBanner from '@/components/components/subscription/UpdateSubscriptionBanner.vue';
import ModalFrame from '@/components/components/common/ModalFrame.vue';
import ModalFrameRoutin from '@/components/components/common/ModalFrameRoutin.vue';
import MobileBottomMenu from './components/global/MobileBottomMenu.vue';

export default {
    name: 'App',
    components: {
        LeftBar,
        HeaderComponent,
        FooterComponent,
        FullPageModal,
        ModalFrame,
        MobileBottomMenu,
        ModalFrameRoutin
    },
    data() {
        return {
            UpdateSubscriptionBanner: markRaw(UpdateSubscriptionBanner),
            showFullPageModal: false,
            showModalFrame: true,
            showModalFrameRoutin: false,
            startTime: new Date(),
            isMenuOpen: false
        };
    },
    computed: {
        ...mapGetters('user', ['isAuthenticated', 'messagesLength', 'isSubscribed'])
    },
    watch: {
        messagesLength(newVal) {
            if (newVal >= 10) {
                this.showFullPageModal = true;
            }
        }
    },
    methods: {
        toggleMenu() {
            this.isMenuOpen = !this.isMenuOpen;
        },
        closeMenu() {
            this.isMenuOpen = false;
        },
        shouldShowModalFrame() {
            if (!this.isAuthenticated) {
                this.showModalFrame = true;
            }
        },
        handleCloseFullPageModal() {
            this.showFullPageModal = false;
        },
        closeAndRedirect() {
            this.showModalFrame = false;
            this.$router.push({ path: '/' });
        }
    }
}
</script>
