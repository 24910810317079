const state = {
    chats: {} // Initial chats array
};

const getters = {
    // Massages array for card
    getMessagesForCard: (state) => (cardId) => {
        return state.chats[cardId] || [];
    }
};

const actions = {
    async sendMessage({ commit, state }, { cardId, text }) {
        console.log(state);
        try {
        // Mock Api call
            const newMessage = { text, id: Date.now(), sender: 'You' };
            commit('ADD_MESSAGE', { cardId, message: newMessage });
        } catch (error) {
            console.error('Error sending message:', error);
        }
    }
};

const mutations = {
    // Adds message
    ADD_MESSAGE(state, { cardId, message }) {
        if (!state.chats[cardId]) {
            state.chats[cardId] = [];
        }
        state.chats[cardId].push(message);
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
