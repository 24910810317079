<template>
    <aside class="left-bar">
        <div class="left-bar__header--mobile">
            <LogoHome @click="$emit('close-menu')"/>
            <HeaderActions @click="$emit('close-menu')"/>
            <button class="left-bar__close-btn" @click="$emit('close-menu')">x</button>
        </div>
        <div class="left-bar__inner">
            <MainMenu @close-menu="$emit('close-menu')"/>
        </div>
    </aside>
</template>

<script>
import MainMenu from "@/components/global/MainMenu.vue";
import LogoHome from '@/components/components/header/LogoHome.vue';
import HeaderActions from '@/components/components/header/HeaderActions.vue';

export default {
    name: 'LeftBar',
    components: {
        MainMenu,
        LogoHome,
        HeaderActions
    }
}
</script>

<style scoped lang="scss">
    @import '@/assets/scss/components/components/leftBar.scss';
</style>