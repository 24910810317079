<template>
    <div class="footer__features">
        <p class="footer__features-title">Features</p>
        <ul class="footer__features-list">
            <li class="footer__features-item" @click="navigate('/gallery')">Generate Image</li>
            <li class="footer__features-item" @click="navigate('/chat')">Chat</li>
            <li class="footer__features-item" @click="navigate('/create')">Create Character</li>
            <li class="footer__features-item" @click="navigate('/gallery')">Photo Galery</li>
            <li class="footer__features-item" @click="navigate('/myai')">My AI</li>
        </ul>
    </div>
</template>

<script>
export default {
    name: 'FooterFeatures',
    methods: {
        navigate(path) {
            this.$router.push({ path });
        }
    }
}
</script>
