<template>
    <footer class="footer">
        <div class="footer__inner">
            <FooterInfo/>
            <FooterFeatures v-if="isAuthenticated" />
            <FooterMore v-if="isAuthenticated" />
        </div>
    </footer>
</template>

<script>
import FooterInfo from '@/components/components/footer/FooterInfo.vue';
import FooterFeatures from '@/components/components/footer/FooterFeatures.vue';
import FooterMore from '@/components/components/footer/FooterMore.vue';
import { mapGetters } from 'vuex';

export default {
    name: 'FooterComponent',
    components: {
        FooterInfo,
        FooterFeatures,
        FooterMore
    },
    computed: {
        ...mapGetters('user', ['isAuthenticated'])
    }
}
</script>

<style lang="scss">
    @import '@/assets/scss/components/global/footerComponent.scss';
</style>
