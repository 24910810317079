import { getUserByEmail, validateUser } from '@/utils/mocks/mocks';

const state = {
    user: null,
    token: null,
    favoriteMembers: [1, 2, 3, 4],
    chatMembers: [1, 2, 3, 4],
    messagesLength: 0
};

const mutations = {
    setUser(state, user) {
        state.user = user;
    },
    setToken(state, token) {
        state.token = token;
    },
    clearAuthData(state) {
        state.user = null;
        state.token = null;
        state.chatMembers = [];
    },
    updateFavoriteMembers(state, favoriteMembers) {
        state.favoriteMembers = favoriteMembers;
    },
    updateChatMembers(state, chatMembers) {
        state.chatMembers = chatMembers;
    },
    updateMessagesLength(state, messagesLength) {
        state.messagesLength = messagesLength;
    }
};

const actions = {
    async login({ commit }, { email, password }) {
        try {
            const response = await validateUser(email, password);
            if (response.success) {
                const user = await getUserByEmail(email);
                if (user) {
                    commit('setUser', user);
                    commit('setToken', response.token);
                    commit('updateChatMembers', user.chatMembers || []);
                    commit('updateFavoriteMembers', user.favoriteMembers || []);
                    return true;
                }
            }
            return false;
        } catch (error) {
            console.error('An error occurred during login:', error);
            return false;
        }
    },
    logout({ commit }) {
        commit('clearAuthData');
    },
    toggleFavorite({ commit }, cardId) {
        let updatedMembers = [...state.favoriteMembers];
        const index = updatedMembers.findIndex(member => member === cardId);

        if (index !== -1) {
            updatedMembers.splice(index, 1);
        } else {
            updatedMembers.push(cardId);
        }

        commit('updateFavoriteMembers', updatedMembers);
    },
    addChatMember({ state, commit }, cardId) {
        if (!state.chatMembers.includes(cardId)) {
            const updatedChatMembers = [...state.chatMembers, cardId];
            commit('updateChatMembers', updatedChatMembers);
        }
    },
    removeChatMember({ state, commit }, cardId) {
        const updatedChatMembers = state.chatMembers.filter(memberId => memberId !== cardId);
        commit('updateChatMembers', updatedChatMembers);
    },
    addMessagesLength({ state, commit }) {
        const updatedMessagesLength = state.messagesLength += 1;
        commit('updateMessagesLength', updatedMessagesLength);
    }
};

const getters = {
    isAuthenticated(state) {
        return !!state.token;
    },
    firstName(state) {
        return state.user ? state.user.firstName : null;
    },
    lastName(state) {
        return state.user ? state.user.lastName : '';
    },
    isSubscribed(state) {
        return state.user ? state.user.subscribed : '';
    },
    userTokens(state) {
        return state.user ? state.user.tokens : 0;
    },
    userPlan(state) {
        return state.user ? state.user.userPlan : null;
    },
    user(state) {
        return state.user ? state.user : null;
    },
    favoriteMembers(state) {
        return state.favoriteMembers;
    },
    createdMembers(state) {
        return state.user ? state.user.createdMembers : [];
    },
    chatMembers(state) {
        return state.chatMembers;
    },
    messagesLength(state) {
        return state.messagesLength;
    }
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};
