<template>
    <div class="banner-subscription">
        <img :src="require('@/assets/img/photos/photo1.png')" alt="Banner Image" class="banner-subscription__img">
        <div class="banner-subscription__info">
            <p class="banner-subscription__title">Upgrade Now for<br/><span>Unlimited Messaging</span></p>
            <p class="banner-subscription__sub-title">Our Premium subscription offers:</p>
            <ul class="banner-subscription__list">
                <li class="banner-subscription__list-item">Unlimited AI Girlfriend creation</li>
                <li class="banner-subscription__list-item">UNLIMITED chat messages, delivered 3x faster</li>
                <li class="banner-subscription__list-item">Access to generate the spiciest photos</li>
            </ul>
            <button class="banner-subscription__btn" @click="handleClick">
                Update Subscription 👑
            </button>
        </div>
        <button class="icon icon--close banner-subscription__close" @click="close"></button>
    </div>
</template>

<script>
export default {
    name: 'UpdateSubscriptionBanner',
    methods: {
        handleClick() {
            this.$router.push({ path: '/update' });
            this.$emit('close');
        },
        close() {
            this.$emit('close');
        }
    }
}
</script>

<style lang="scss">
    @import '@/assets/scss/components/components/subscription/updateSubscriptionBanner.scss';
</style>
