import { createApp } from 'vue';
import { createRouter, createWebHistory } from 'vue-router';
import './assets/scss/global.scss'
import App from './App.vue';
import store from './store';

// Creating Routes
const routes = [
    {
        name: 'Home',
        path: '/',
        component: () => import('@/components/views/HomePageView')
    },
    {
        name: 'Login',
        path: '/login',
        component: () => import('@/components/views/LoginView')
    },
    {
        name: 'MyAccount',
        path: '/myaccount',
        component: () => import('@/components/views/MyAccountView')
    },
    {
        name: 'UpdateSubscription',
        path: '/update',
        component: () => import('@/components/views/UpdateSubscriptionView')
    },
    {
        name: 'Chat',
        path: '/chat',
        component: () => import('@/components/views/ChatView')
    },
    {
        path: '/chat/:id',
        name: 'ChatComponent',
        component: () => import('@/components/components/chat/ChatComponent'),
        props: true
    },
    {
        name: 'Gallery',
        path: '/gallery',
        component: () => import('@/components/views/GalleryView')
    },
    {
        name: 'MyList',
        path: '/mylist',
        component: () => import('@/components/views/MyListView')
    },
    {
        name: 'MyAI',
        path: '/myai',
        component: () => import('@/components/views/MyAIView')
    },
    {
        name: 'Settings',
        path: '/settings',
        component: () => import('@/components/views/SettingsView')
    },
    {
        name: 'Create Character',
        path: '/create',
        component: () => import('@/components/views/CreateCharacterView')
    },
    {
        name: 'AddTokens',
        path: '/addtokens',
        component: () => import('@/components/views/AddTokensView')
    },
    {
        name: 'CardDetail',
        path: '/card/:id',
        component: () => import('@/components/components/cards/CardDetail')
    },
    {
        name: 'TermsPolicy',
        path: '/termspolicy',
        component: () => import('@/components/views/TermsPolicyView')
    },
    {
        name: 'AffiliateProgram',
        path: '/affiliate',
        component: () => import('@/components/views/AffiliateProgramView')
    }
];

// Creates a router instance
const router = createRouter({
    history: createWebHistory(),
    routes
});

// Creates App
const app = createApp(App);
app.use(router);
app.use(store);
app.mount('#app');
