<template>
    <div class="modal-frame-body">
        <div class="modal-frame">
            <div class="modal-frame__content">
                <LogoHome />
                <div class="modal-frame__content-body">
                    <p class="modal-frame__content-title">AI Romance Chat</p>
                    <p class="modal-frame__content-text">Explore the Realm of Virtual Companionship! Engage with AI Characters or Craft Your Own Unique AI Companion for Conversations on Any Subject.</p>
                    <button class="modal-frame__content-btn" @click="closeAndRedirect">Start chatting</button>
                </div>
            </div>
            <div class="modal-frame__back"></div>
        </div>
    </div>
</template>

<script>
import LogoHome from '@/components/components/header/LogoHome.vue';

export default {
    name: 'ModalFrame',
    components: {
        LogoHome
    },
    props: {
        closeAndRedirect: {
            type: Function,
            required: true
        }
    }
};
</script>

<style scoped lang="scss">
    @import '@/assets/scss/components/components/common/modalFrame.scss';
</style>
