<template>
    <div class="mobile-menu">
        <ul class="mobile-menu__list">
            <MenuItem title=""
                      id="explore"
                      path="/explore"/>
            <MenuItem title=""
                      id="chat"
                      path="/chat"/>
            <MenuItem title=""
                      id="mylist"
                      path="/mylist"/>
            <MenuItem title=""
                      id="photo"
                      path="/gallery"/>
            <MenuItem title=""
                      id="settings"
                      path="/settings"/>
        </ul>
    </div>
</template>

<script>
import MenuItem from '@/components/global/MenuItem.vue';

export default {
    name: 'MobileBottomMenu',
    components: {
        MenuItem
    }
}
</script>

<style scoped lang="scss">
    @import '@/assets/scss/components/components/menu/mobileBottomMenu.scss';
</style>
