<template>
    <div v-if="!isAuthenticated" class="header__actions">
        <button class="free-sign-up">Free Sign up</button>
        <button class="login" @click="$router.push({ path: '/login' })">Log In</button>
    </div>
    <div v-else class="header__actions">
        <button class="user-name" @click="$router.push({ path: '/myaccount' })">
            <span class="user--circle"><span class="item--text">{{ userInitial }}</span></span>{{ user.firstName }} {{ user.lastName }}
        </button>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
    name: 'HeaderActions',
    computed: {
        ...mapGetters('user', ['isAuthenticated', 'user']),
        userInitial() {
            return this.user.firstName ? this.user.firstName.charAt(0).toUpperCase() : '';
        }
    }
}
</script>

<style scoped lang="scss">
    @import '@/assets/scss/components/components/header/headerActions.scss';
</style>
