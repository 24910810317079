<template>
    <div class="main-menu">
        <ul class="main-menu__list">
            <MenuItem
                title="Explore"
                id="explore"
                path="/"
                :isActive="activeItem === 'explore'"
                @menu-clicked="setActiveItem"
            />
            <MenuItem
                title="Chat"
                id="chat"
                path="/chat"
                :isActive="activeItem === 'chat'"
                @menu-clicked="setActiveItem"
            />
            <MenuItem
                title="Gallery"
                id="photo"
                path="/gallery"
                :isActive="activeItem === 'photo'"
                @menu-clicked="setActiveItem"
            />
            <MenuItem
                title="My Girls"
                id="mylist"
                path="/mylist"
                :isActive="activeItem === 'mylist'"
                @menu-clicked="setActiveItem"
            />
            <!-- Hides My AI if user not logged in -->
            <MenuItem
                v-if="isAuthenticated"
                title="My AI"
                id="myai"
                path="/myai"
                :isActive="activeItem === 'myai'"
                @menu-clicked="setActiveItem"
            />
            <MenuItem
                title="Settings"
                id="settings"
                path="/settings"
                :isActive="activeItem === 'settings'"
                @menu-clicked="setActiveItem"
            />
        </ul>
        <ul class="main-menu__list main-menu__list--aditional">
            <!-- Hides Create Character and Tokens if user not logged in -->
            <MenuItem
                title="+ Create Character"
                id="create"
                path="/create"
                :isActive="activeItem === 'create'"
                @menu-clicked="setActiveItem"
            />
            <MenuItem
                v-if="isAuthenticated"
                title="Tokens"
                id="addtokens"
                path="/addtokens"
                :userTokens="userTokens"
                :isActive="activeItem === 'addtokens'"
                @menu-clicked="setActiveItem"
            />
        </ul>
    </div>
</template>

<script>
import MenuItem from '@/components/global/MenuItem.vue';
import { mapGetters } from 'vuex';

export default {
    name: 'MainMenu',
    components: {
        MenuItem
    },
    data() {
        return {
            activeItem: null
        };
    },
    methods: {
        handleCloseMenu() {
            this.$emit('close-menu');
        },
        setActiveItem(itemId) {
            this.activeItem = itemId;
            this.handleCloseMenu();
        }
    },
    computed: {
        ...mapGetters('user', ['isAuthenticated', 'userTokens'])
    }
}
</script>

<style scoped lang="scss">
@import '@/assets/scss/components/components/menu/mainMenu.scss';
</style>
