<template>
    <div class="modal-frame-body">
        <div class="modal-frame modal-frame--routin">
            <div class="modal-frame__content">
                <LogoHome />
                <div class="modal-frame__content-body">
                    <p class="modal-frame__content-text">
                        Our character chat will be available soon. Meanwhile, choose your communication method and provide your email for updates.
                    </p>

                    <div class="modal-frame__content-wrapper--routin">
                        <p class="chat-img">I am willing to discuss any topic. Please let me know your expectations</p>
                        <span class="modal-frame__content-name">Olivia</span>
                        <div class="modal-frame__content-info">
                            <div class="modal-frame__content-info-item">
                                <span class="item-text">Spicy</span>
                                <span class="item-icon icon icon--hot"></span>
                            </div>
                            <div class="modal-frame__content-info-item">
                                <span class="item-text">Everyday routine and support</span>
                                <span class="item-icon icon icon--date"></span>
                            </div>
                            <div class="modal-frame__content-info-item">
                                <span class="item-text">Dare to dream</span>
                                <span class="item-icon icon icon--horse"></span>
                            </div>
                            <div class="modal-frame__content-info-item">
                                <span class="item-text">Rumors and informal conversations</span>
                                <span class="item-icon icon icon--chat-grey"></span>
                            </div>
                        </div>
                        <input
                            type="email"
                            class="custom-input"
                            id="email"
                            placeholder="Email"
                            v-model="localEmail"
                            required
                        />
                        <p v-if="errorMessage" class="error-message">{{ errorMessage }}</p>
                        <button class="modal-frame__content-btn" @click="getStarted"><span class="item-icon icon icon--hot"></span>Get Started</button>
                    </div>

                </div>
            </div>
            <div class="modal-frame__back"></div>
        </div>
    </div>
</template>

<script>
import LogoHome from '@/components/components/header/LogoHome.vue';

export default {
    name: 'ModalFrameRoutin',
    components: {
        LogoHome,
    },
    data() {
        return {
            localEmail: '',
            errorMessage: '',
        };
    },
    methods: {
        getStarted() {
            if (this.validateEmail(this.localEmail)) {
                this.errorMessage = '';
                // TODO: API call to send email
                console.log('Email is valid:', this.localEmail);
            } else {
                this.errorMessage = 'Please enter a valid email address.';
            }
        },
        validateEmail(email) {
            const regexp = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            return regexp.test(email);
        },
    },
};
</script>

<style scoped lang="scss">
    @import '@/assets/scss/components/components/common/modalFrame.scss';
    @import '@/assets/scss/components/components/common/modalFrameRoutin.scss';
</style>
