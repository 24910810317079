<template>
    <header class="header">
        <button class="header__menu-btn" @click="$emit('toggle-menu')">
            <img :src="require('@/assets/img/icons/burger.svg')" alt="Banner Image" class="banner-subscription__img">
        </button>
        <LogoHome/>
        <div class="header__banner">
            <p class="header__banner-title item--text">
                <strong>Exclusive Discount!</strong> Save Up to 75% <span class="hightlight">on  Your First Subscription</span>
            </p>
            <button class="header__banner-btn" @click="$router.push({ path: '/update' })">Update Subscription 👑 </button>
        </div>
        <HeaderActions/>
    </header>
</template>

<script>
import { mapGetters } from 'vuex';
import LogoHome from '@/components/components/header/LogoHome.vue';
import HeaderActions from '@/components/components/header/HeaderActions.vue';

export default {
    name: 'HeaderComponent',
    components: {
        LogoHome,
        HeaderActions
    },
    computed: {
        ...mapGetters('user', ['isAuthenticated', 'user']),
        userInitial() {
            return this.user.firstName ? this.user.firstName.charAt(0).toUpperCase() : '';
        }
    }
}
</script>

<style scoped lang="scss">
    @import '@/assets/scss/components/global/headerComponent.scss';
</style>
