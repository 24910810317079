<template>
    <div class="footer__more">
        <p class="footer__more-title">More</p>
        <ul class="footer__more-list">
            <li class="footer__features-item" @click="navigate('/termspolicy')">Terms and Policies</li>
            <li class="footer__features-item" @click="navigate('/affiliate')">Affiliate Program</li>
        </ul>
    </div>
</template>

<script>
export default {
    name: 'FooterMore',
    methods: {
        navigate(path) {
            this.$router.push({ path });
        }
    }
}
</script>
