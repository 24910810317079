<template>
    <div class="full-page-modal" v-if="show">
        <div class="full-page-modal__content">
            <component :is="bannerComponent" @close="closeModal"/>
        </div>
    </div>
</template>

<script>
export default {
    name: 'FullPageModal',
    props: {
        show: {
            type: Boolean,
            required: true
        },
        bannerComponent: {
            type: [Object, Function],
            required: true
        },
        closeHandler: {
            type: Function,
            required: true
        }
    },
    methods: {
        closeModal() {
            this.closeHandler();
        }
    }
};
</script>

<style scoped lang="scss">
    @import '@/assets/scss/components/components/modal/fullPageModal.scss';
</style>
