export const getCards = async () => {
    await new Promise(resolve => setTimeout(resolve, 1000));

    return [
        {
            id: 1,
            name: 'Alice',
            badgeText: 'New',
            description: 'Alice enjoys spending time in nature and has a passion for photography. She loves capturing beautiful moments with her camera.',
            profileImg: require('@/assets/img/cards/card.png'),
            avatar: require('@/assets/img/cards/avatar.png'),
            attributes: [
                { id: 'ethnicity', value: 'Caucasian', displayName: 'Ethnicity' },
                { id: 'age', value: '28', displayName: 'Age' },
                { id: 'eye', value: 'Blue', displayName: 'Eye Color' },
                { id: 'hairStyle', value: 'Straight', displayName: 'Hair Style' },
                { id: 'hairLength', value: 'Medium', displayName: 'Hair Length' },
                { id: 'hairColor', value: 'Blonde', displayName: 'Hair Color' },
                { id: 'bodyType', value: 'Slim', displayName: 'Body Type' },
                { id: 'breastSize', value: 'Small', displayName: 'Breast Size' },
                { id: 'buttSize', value: 'Small', displayName: 'Butt Size' },
                { id: 'hobbies', value: 'Photography', displayName: 'Hobbies' },
                { id: 'personality', value: 'Adventurous', displayName: 'Personality of your AI' },
                { id: 'occupation', value: 'Photographer', displayName: 'Occupation' }
            ],
            pictures: {
                title: 'Alice\'s Photos',
                text: 'Alice enjoys capturing beautiful moments. Check out her photos!',
                images: [
                    { src: require('@/assets/img/photos/photo2.png'), dateAdded: '2024-07-02' },
                    { src: require('@/assets/img/photos/photo1.png'), dateAdded: '2024-07-01' },
                    { src: require('@/assets/img/photos/photo3.png'), dateAdded: '2024-07-03' },
                    { src: require('@/assets/img/photos/photo4.png'), dateAdded: '2024-07-04' },
                    { src: require('@/assets/img/photos/photo5.png'), dateAdded: '2024-07-05' },
                    { src: require('@/assets/img/photos/photo2.png'), dateAdded: '2024-07-06' }
                ],
            },
            audioFiles: [
                { src: 'https://www.soundhelix.com/examples/mp3/SoundHelix-Song-1.mp3', type: 'audio/mpeg' },
                { src: 'https://www.soundhelix.com/examples/mp3/SoundHelix-Song-2.mp3', type: 'audio/mpeg' }
            ]
        },
        {
            id: 2,
            name: 'Liza',
            badgeText: 'Popular',
            description: 'Liza is a social butterfly who loves to attend events and meet new people. He has a vibrant personality and is always up for an adventure.',
            profileImg: require('@/assets/img/cards/card.png'),
            avatar: require('@/assets/img/cards/avatar.png'),
            attributes: [
                { id: 'ethnicity', value: 'African American', displayName: 'Ethnicity' },
                { id: 'age', value: '32', displayName: 'Age' },
                { id: 'eye', value: 'Brown', displayName: 'Eye Color' },
                { id: 'hairStyle', value: 'Curly', displayName: 'Hair Style' },
                { id: 'hairLength', value: 'Short', displayName: 'Hair Length' },
                { id: 'hairColor', value: 'Black', displayName: 'Hair Color' },
                { id: 'bodyType', value: 'Athletic', displayName: 'Body Type' },
                { id: 'breastSize', value: 'N/A', displayName: 'Breast Size' },
                { id: 'buttSize', value: 'N/A', displayName: 'Butt Size' },
                { id: 'hobbies', value: 'Sports', displayName: 'Hobbies' },
                { id: 'personality', value: 'Outgoing', displayName: 'Personality of your AI' },
                { id: 'occupation', value: 'Event Planner', displayName: 'Occupation' }
            ],
            pictures: {
                title: 'Liza\'s Photos',
                text: 'Liza loves social events and is always in the spotlight. Here are some of his moments!',
                images: [
                    { src: require('@/assets/img/photos/photo2.png'), dateAdded: '2024-07-02' },
                    { src: require('@/assets/img/photos/photo1.png'), dateAdded: '2024-07-01' },
                    { src: require('@/assets/img/photos/photo3.png'), dateAdded: '2024-07-03' },
                    { src: require('@/assets/img/photos/photo4.png'), dateAdded: '2024-07-04' },
                    { src: require('@/assets/img/photos/photo5.png'), dateAdded: '2024-07-05' },
                    { src: require('@/assets/img/photos/photo2.png'), dateAdded: '2024-07-06' }
                ],
            },
            audioFiles: [
                { src: 'https://www.soundhelix.com/examples/mp3/SoundHelix-Song-1.mp3', type: 'audio/mpeg' },
                { src: 'https://www.soundhelix.com/examples/mp3/SoundHelix-Song-2.mp3', type: 'audio/mpeg' }
            ]
        },
        {
            id: 3,
            name: 'Magda',
            badgeText: 'Online',
            description: 'Magdalina is always online and loves connecting with others. He has a friendly and engaging personality.',
            profileImg: require('@/assets/img/cards/card.png'),
            avatar: require('@/assets/img/cards/avatar.png'),
            attributes: [
                { id: 'ethnicity', value: 'Asian', displayName: 'Ethnicity' },
                { id: 'age', value: '26', displayName: 'Age' },
                { id: 'eye', value: 'Black', displayName: 'Eye Color' },
                { id: 'hairStyle', value: 'Spiky', displayName: 'Hair Style' },
                { id: 'hairLength', value: 'Short', displayName: 'Hair Length' },
                { id: 'hairColor', value: 'Brown', displayName: 'Hair Color' },
                { id: 'bodyType', value: 'Lean', displayName: 'Body Type' },
                { id: 'breastSize', value: 'N/A', displayName: 'Breast Size' },
                { id: 'buttSize', value: 'N/A', displayName: 'Butt Size' },
                { id: 'hobbies', value: 'Gaming', displayName: 'Hobbies' },
                { id: 'personality', value: 'Friendly', displayName: 'Personality of your AI' },
                { id: 'occupation', value: 'Software Developer', displayName: 'Occupation' }
            ],
            pictures: {
                title: 'Magdalina\'s Photos',
                text: 'Magdalina is always online and loves to share his moments. Check out his photos!',
                images: [
                    { src: require('@/assets/img/photos/photo2.png'), dateAdded: '2024-07-02' },
                    { src: require('@/assets/img/photos/photo1.png'), dateAdded: '2024-07-01' },
                    { src: require('@/assets/img/photos/photo3.png'), dateAdded: '2024-07-03' },
                    { src: require('@/assets/img/photos/photo4.png'), dateAdded: '2024-07-04' },
                    { src: require('@/assets/img/photos/photo5.png'), dateAdded: '2024-07-05' },
                    { src: require('@/assets/img/photos/photo2.png'), dateAdded: '2024-07-06' }
                ],
            },
            audioFiles: [
                { src: 'https://www.soundhelix.com/examples/mp3/SoundHelix-Song-1.mp3', type: 'audio/mpeg' },
                { src: 'https://www.soundhelix.com/examples/mp3/SoundHelix-Song-2.mp3', type: 'audio/mpeg' }
            ]
        },
        {
            id: 4,
            name: 'Cherry',
            badgeText: 'Online',
            description: 'Cherry is always online and loves connecting with others. He has a friendly and engaging personality.',
            profileImg: require('@/assets/img/cards/card.png'),
            avatar: require('@/assets/img/cards/avatar.png'),
            attributes: [
                { id: 'ethnicity', value: 'Asian', displayName: 'Ethnicity' },
                { id: 'age', value: '26', displayName: 'Age' },
                { id: 'eye', value: 'Black', displayName: 'Eye Color' },
                { id: 'hairStyle', value: 'Spiky', displayName: 'Hair Style' },
                { id: 'hairLength', value: 'Short', displayName: 'Hair Length' },
                { id: 'hairColor', value: 'Brown', displayName: 'Hair Color' },
                { id: 'bodyType', value: 'Lean', displayName: 'Body Type' },
                { id: 'breastSize', value: 'N/A', displayName: 'Breast Size' },
                { id: 'buttSize', value: 'N/A', displayName: 'Butt Size' },
                { id: 'hobbies', value: 'Gaming', displayName: 'Hobbies' },
                { id: 'personality', value: 'Friendly', displayName: 'Personality of your AI' },
                { id: 'occupation', value: 'Software Developer', displayName: 'Occupation' }
            ],
            pictures: {
                title: 'Cherry\'s Photos',
                text: 'Cherry is always online and loves to share his moments. Check out his photos!',
                images: [
                    { src: require('@/assets/img/photos/photo2.png'), dateAdded: '2024-07-02' },
                    { src: require('@/assets/img/photos/photo1.png'), dateAdded: '2024-07-01' },
                    { src: require('@/assets/img/photos/photo3.png'), dateAdded: '2024-07-03' },
                    { src: require('@/assets/img/photos/photo4.png'), dateAdded: '2024-07-04' },
                    { src: require('@/assets/img/photos/photo5.png'), dateAdded: '2024-07-05' },
                    { src: require('@/assets/img/photos/photo2.png'), dateAdded: '2024-07-06' }
                ],
            },
            audioFiles: [
                { src: 'https://www.soundhelix.com/examples/mp3/SoundHelix-Song-1.mp3', type: 'audio/mpeg' },
                { src: 'https://www.soundhelix.com/examples/mp3/SoundHelix-Song-2.mp3', type: 'audio/mpeg' }
            ]
        },
        {
            id: 5,
            name: 'Banana',
            badgeText: 'Online',
            description: 'Banana is always online and loves connecting with others. He has a friendly and engaging personality.',
            profileImg: require('@/assets/img/cards/card.png'),
            avatar: require('@/assets/img/cards/avatar.png'),
            attributes: [
                { id: 'ethnicity', value: 'Asian', displayName: 'Ethnicity' },
                { id: 'age', value: '26', displayName: 'Age' },
                { id: 'eye', value: 'Black', displayName: 'Eye Color' },
                { id: 'hairStyle', value: 'Spiky', displayName: 'Hair Style' },
                { id: 'hairLength', value: 'Short', displayName: 'Hair Length' },
                { id: 'hairColor', value: 'Brown', displayName: 'Hair Color' },
                { id: 'bodyType', value: 'Lean', displayName: 'Body Type' },
                { id: 'breastSize', value: 'N/A', displayName: 'Breast Size' },
                { id: 'buttSize', value: 'N/A', displayName: 'Butt Size' },
                { id: 'hobbies', value: 'Gaming', displayName: 'Hobbies' },
                { id: 'personality', value: 'Friendly', displayName: 'Personality of your AI' },
                { id: 'occupation', value: 'Software Developer', displayName: 'Occupation' }
            ],
            pictures: {
                title: 'Banana\'s Photos',
                text: 'Banana is always online and loves to share his moments. Check out his photos!',
                images: [
                    { src: require('@/assets/img/photos/photo2.png'), dateAdded: '2024-07-02' },
                    { src: require('@/assets/img/photos/photo1.png'), dateAdded: '2024-07-01' },
                    { src: require('@/assets/img/photos/photo3.png'), dateAdded: '2024-07-03' },
                    { src: require('@/assets/img/photos/photo4.png'), dateAdded: '2024-07-04' },
                    { src: require('@/assets/img/photos/photo5.png'), dateAdded: '2024-07-05' },
                    { src: require('@/assets/img/photos/photo2.png'), dateAdded: '2024-07-06' }
                ],
            },
            audioFiles: [
                { src: 'https://www.soundhelix.com/examples/mp3/SoundHelix-Song-1.mp3', type: 'audio/mpeg' },
                { src: 'https://www.soundhelix.com/examples/mp3/SoundHelix-Song-2.mp3', type: 'audio/mpeg' }
            ]
        },
        {
            id: 6,
            name: 'Sveta',
            badgeText: 'Online',
            description: 'Svetlana is always online and loves connecting with others. He has a friendly and engaging personality.',
            profileImg: require('@/assets/img/cards/card.png'),
            avatar: require('@/assets/img/cards/avatar.png'),
            attributes: [
                { id: 'ethnicity', value: 'Asian', displayName: 'Ethnicity' },
                { id: 'age', value: '26', displayName: 'Age' },
                { id: 'eye', value: 'Black', displayName: 'Eye Color' },
                { id: 'hairStyle', value: 'Spiky', displayName: 'Hair Style' },
                { id: 'hairLength', value: 'Short', displayName: 'Hair Length' },
                { id: 'hairColor', value: 'Brown', displayName: 'Hair Color' },
                { id: 'bodyType', value: 'Lean', displayName: 'Body Type' },
                { id: 'breastSize', value: 'N/A', displayName: 'Breast Size' },
                { id: 'buttSize', value: 'N/A', displayName: 'Butt Size' },
                { id: 'hobbies', value: 'Gaming', displayName: 'Hobbies' },
                { id: 'personality', value: 'Friendly', displayName: 'Personality of your AI' },
                { id: 'occupation', value: 'Software Developer', displayName: 'Occupation' }
            ],
            pictures: {
                title: 'Svetlana\'s Photos',
                text: 'Svetlana is always online and loves to share his moments. Check out his photos!',
                images: [
                    { src: require('@/assets/img/photos/photo2.png'), dateAdded: '2024-07-02' },
                    { src: require('@/assets/img/photos/photo1.png'), dateAdded: '2024-07-01' },
                    { src: require('@/assets/img/photos/photo3.png'), dateAdded: '2024-07-03' },
                    { src: require('@/assets/img/photos/photo4.png'), dateAdded: '2024-07-04' },
                    { src: require('@/assets/img/photos/photo5.png'), dateAdded: '2024-07-05' },
                    { src: require('@/assets/img/photos/photo2.png'), dateAdded: '2024-07-06' }
                ],
            },
            audioFiles: [
                { src: 'https://www.soundhelix.com/examples/mp3/SoundHelix-Song-1.mp3', type: 'audio/mpeg' },
                { src: 'https://www.soundhelix.com/examples/mp3/SoundHelix-Song-2.mp3', type: 'audio/mpeg' }
            ]
        },
        {
            id: 7,
            name: 'Marina',
            badgeText: 'Online',
            description: 'Marina is always online and loves connecting with others. He has a friendly and engaging personality.',
            profileImg: require('@/assets/img/cards/card.png'),
            avatar: require('@/assets/img/cards/avatar.png'),
            attributes: [
                { id: 'ethnicity', value: 'Asian', displayName: 'Ethnicity' },
                { id: 'age', value: '26', displayName: 'Age' },
                { id: 'eye', value: 'Black', displayName: 'Eye Color' },
                { id: 'hairStyle', value: 'Spiky', displayName: 'Hair Style' },
                { id: 'hairLength', value: 'Short', displayName: 'Hair Length' },
                { id: 'hairColor', value: 'Brown', displayName: 'Hair Color' },
                { id: 'bodyType', value: 'Lean', displayName: 'Body Type' },
                { id: 'breastSize', value: 'N/A', displayName: 'Breast Size' },
                { id: 'buttSize', value: 'N/A', displayName: 'Butt Size' },
                { id: 'hobbies', value: 'Gaming', displayName: 'Hobbies' },
                { id: 'personality', value: 'Friendly', displayName: 'Personality of your AI' },
                { id: 'occupation', value: 'Software Developer', displayName: 'Occupation' }
            ],
            pictures: {
                title: 'Marina\'s Photos',
                text: 'Marina is always online and loves to share his moments. Check out his photos!',
                images: [
                    { src: require('@/assets/img/photos/photo2.png'), dateAdded: '2024-07-02' },
                    { src: require('@/assets/img/photos/photo1.png'), dateAdded: '2024-07-01' },
                    { src: require('@/assets/img/photos/photo3.png'), dateAdded: '2024-07-03' },
                    { src: require('@/assets/img/photos/photo4.png'), dateAdded: '2024-07-04' },
                    { src: require('@/assets/img/photos/photo5.png'), dateAdded: '2024-07-05' },
                    { src: require('@/assets/img/photos/photo2.png'), dateAdded: '2024-07-06' }
                ],
            },
            audioFiles: [
                { src: 'https://www.soundhelix.com/examples/mp3/SoundHelix-Song-1.mp3', type: 'audio/mpeg' },
                { src: 'https://www.soundhelix.com/examples/mp3/SoundHelix-Song-2.mp3', type: 'audio/mpeg' }
            ]
        },
        {
            id: 8,
            name: 'Irina',
            badgeText: 'Online',
            description: 'Irina is always online and loves connecting with others. He has a friendly and engaging personality.',
            profileImg: require('@/assets/img/cards/card.png'),
            avatar: require('@/assets/img/cards/avatar.png'),
            attributes: [
                { id: 'ethnicity', value: 'Asian', displayName: 'Ethnicity' },
                { id: 'age', value: '26', displayName: 'Age' },
                { id: 'eye', value: 'Black', displayName: 'Eye Color' },
                { id: 'hairStyle', value: 'Spiky', displayName: 'Hair Style' },
                { id: 'hairLength', value: 'Short', displayName: 'Hair Length' },
                { id: 'hairColor', value: 'Brown', displayName: 'Hair Color' },
                { id: 'bodyType', value: 'Lean', displayName: 'Body Type' },
                { id: 'breastSize', value: 'N/A', displayName: 'Breast Size' },
                { id: 'buttSize', value: 'N/A', displayName: 'Butt Size' },
                { id: 'hobbies', value: 'Gaming', displayName: 'Hobbies' },
                { id: 'personality', value: 'Friendly', displayName: 'Personality of your AI' },
                { id: 'occupation', value: 'Software Developer', displayName: 'Occupation' }
            ],
            pictures: {
                title: 'Irina\'s Photos',
                text: 'Irina is always online and loves to share his moments. Check out his photos!',
                images: [
                    { src: require('@/assets/img/photos/photo2.png'), dateAdded: '2024-07-02' },
                    { src: require('@/assets/img/photos/photo1.png'), dateAdded: '2024-07-01' },
                    { src: require('@/assets/img/photos/photo3.png'), dateAdded: '2024-07-03' },
                    { src: require('@/assets/img/photos/photo4.png'), dateAdded: '2024-07-04' },
                    { src: require('@/assets/img/photos/photo5.png'), dateAdded: '2024-07-05' },
                    { src: require('@/assets/img/photos/photo2.png'), dateAdded: '2024-07-06' }
                ],
            },
            audioFiles: [
                { src: 'https://www.soundhelix.com/examples/mp3/SoundHelix-Song-1.mp3', type: 'audio/mpeg' },
                { src: 'https://www.soundhelix.com/examples/mp3/SoundHelix-Song-2.mp3', type: 'audio/mpeg' }
            ]
        },
        {
            id: 9,
            name: 'Natalia',
            badgeText: 'Online',
            description: 'Natalia is always online and loves connecting with others. He has a friendly and engaging personality.',
            profileImg: require('@/assets/img/cards/card.png'),
            avatar: require('@/assets/img/cards/avatar.png'),
            attributes: [
                { id: 'ethnicity', value: 'Asian', displayName: 'Ethnicity' },
                { id: 'age', value: '26', displayName: 'Age' },
                { id: 'eye', value: 'Black', displayName: 'Eye Color' },
                { id: 'hairStyle', value: 'Spiky', displayName: 'Hair Style' },
                { id: 'hairLength', value: 'Short', displayName: 'Hair Length' },
                { id: 'hairColor', value: 'Brown', displayName: 'Hair Color' },
                { id: 'bodyType', value: 'Lean', displayName: 'Body Type' },
                { id: 'breastSize', value: 'N/A', displayName: 'Breast Size' },
                { id: 'buttSize', value: 'N/A', displayName: 'Butt Size' },
                { id: 'hobbies', value: 'Gaming', displayName: 'Hobbies' },
                { id: 'personality', value: 'Friendly', displayName: 'Personality of your AI' },
                { id: 'occupation', value: 'Software Developer', displayName: 'Occupation' }
            ],
            pictures: {
                title: 'Natalia\'s Photos',
                text: 'Natalia is always online and loves to share his moments. Check out his photos!',
                images: [
                    { src: require('@/assets/img/photos/photo2.png'), dateAdded: '2024-07-02' },
                    { src: require('@/assets/img/photos/photo1.png'), dateAdded: '2024-07-01' },
                    { src: require('@/assets/img/photos/photo3.png'), dateAdded: '2024-07-03' },
                    { src: require('@/assets/img/photos/photo4.png'), dateAdded: '2024-07-04' },
                    { src: require('@/assets/img/photos/photo5.png'), dateAdded: '2024-07-05' },
                    { src: require('@/assets/img/photos/photo2.png'), dateAdded: '2024-07-06' }
                ],
            },
            audioFiles: [
                { src: 'https://www.soundhelix.com/examples/mp3/SoundHelix-Song-1.mp3', type: 'audio/mpeg' },
                { src: 'https://www.soundhelix.com/examples/mp3/SoundHelix-Song-2.mp3', type: 'audio/mpeg' }
            ]
        },
        {
            id: 10,
            name: 'Nagets',
            badgeText: 'Online',
            description: 'Nagets is always online and loves connecting with others. He has a friendly and engaging personality.',
            profileImg: require('@/assets/img/cards/card.png'),
            avatar: require('@/assets/img/cards/avatar.png'),
            attributes: [
                { id: 'ethnicity', value: 'Asian', displayName: 'Ethnicity' },
                { id: 'age', value: '26', displayName: 'Age' },
                { id: 'eye', value: 'Black', displayName: 'Eye Color' },
                { id: 'hairStyle', value: 'Spiky', displayName: 'Hair Style' },
                { id: 'hairLength', value: 'Short', displayName: 'Hair Length' },
                { id: 'hairColor', value: 'Brown', displayName: 'Hair Color' },
                { id: 'bodyType', value: 'Lean', displayName: 'Body Type' },
                { id: 'breastSize', value: 'N/A', displayName: 'Breast Size' },
                { id: 'buttSize', value: 'N/A', displayName: 'Butt Size' },
                { id: 'hobbies', value: 'Gaming', displayName: 'Hobbies' },
                { id: 'personality', value: 'Friendly', displayName: 'Personality of your AI' },
                { id: 'occupation', value: 'Software Developer', displayName: 'Occupation' }
            ],
            pictures: {
                title: 'Nagets\'s Photos',
                text: 'Nagets is always online and loves to share his moments. Check out his photos!',
                images: [
                    { src: require('@/assets/img/photos/photo2.png'), dateAdded: '2024-07-02' },
                    { src: require('@/assets/img/photos/photo1.png'), dateAdded: '2024-07-01' },
                    { src: require('@/assets/img/photos/photo3.png'), dateAdded: '2024-07-03' },
                    { src: require('@/assets/img/photos/photo4.png'), dateAdded: '2024-07-04' },
                    { src: require('@/assets/img/photos/photo5.png'), dateAdded: '2024-07-05' },
                    { src: require('@/assets/img/photos/photo2.png'), dateAdded: '2024-07-06' }
                ],
            },
            audioFiles: [
                { src: 'https://www.soundhelix.com/examples/mp3/SoundHelix-Song-1.mp3', type: 'audio/mpeg' },
                { src: 'https://www.soundhelix.com/examples/mp3/SoundHelix-Song-2.mp3', type: 'audio/mpeg' }
            ]
        },
        {
            id: 11,
            name: 'Sharlota',
            badgeText: 'Online',
            description: 'Sharlota is always online and loves connecting with others. He has a friendly and engaging personality.',
            profileImg: require('@/assets/img/cards/card.png'),
            avatar: require('@/assets/img/cards/avatar.png'),
            attributes: [
                { id: 'ethnicity', value: 'Asian', displayName: 'Ethnicity' },
                { id: 'age', value: '26', displayName: 'Age' },
                { id: 'eye', value: 'Black', displayName: 'Eye Color' },
                { id: 'hairStyle', value: 'Spiky', displayName: 'Hair Style' },
                { id: 'hairLength', value: 'Short', displayName: 'Hair Length' },
                { id: 'hairColor', value: 'Brown', displayName: 'Hair Color' },
                { id: 'bodyType', value: 'Lean', displayName: 'Body Type' },
                { id: 'breastSize', value: 'N/A', displayName: 'Breast Size' },
                { id: 'buttSize', value: 'N/A', displayName: 'Butt Size' },
                { id: 'hobbies', value: 'Gaming', displayName: 'Hobbies' },
                { id: 'personality', value: 'Friendly', displayName: 'Personality of your AI' },
                { id: 'occupation', value: 'Software Developer', displayName: 'Occupation' }
            ],
            pictures: {
                title: 'Sharlota\'s Photos',
                text: 'Sharlota is always online and loves to share his moments. Check out his photos!',
                images: [
                    { src: require('@/assets/img/photos/photo2.png'), dateAdded: '2024-07-02' },
                    { src: require('@/assets/img/photos/photo1.png'), dateAdded: '2024-07-01' },
                    { src: require('@/assets/img/photos/photo3.png'), dateAdded: '2024-07-03' },
                    { src: require('@/assets/img/photos/photo4.png'), dateAdded: '2024-07-04' },
                    { src: require('@/assets/img/photos/photo5.png'), dateAdded: '2024-07-05' },
                    { src: require('@/assets/img/photos/photo2.png'), dateAdded: '2024-07-06' }
                ],
            },
            audioFiles: [
                { src: 'https://www.soundhelix.com/examples/mp3/SoundHelix-Song-1.mp3', type: 'audio/mpeg' },
                { src: 'https://www.soundhelix.com/examples/mp3/SoundHelix-Song-2.mp3', type: 'audio/mpeg' }
            ]
        },
        {
            id: 12,
            name: 'Charen',
            badgeText: 'Online',
            description: 'Charen is always online and loves connecting with others. He has a friendly and engaging personality.',
            profileImg: require('@/assets/img/cards/card.png'),
            avatar: require('@/assets/img/cards/avatar.png'),
            attributes: [
                { id: 'ethnicity', value: 'Asian', displayName: 'Ethnicity' },
                { id: 'age', value: '26', displayName: 'Age' },
                { id: 'eye', value: 'Black', displayName: 'Eye Color' },
                { id: 'hairStyle', value: 'Spiky', displayName: 'Hair Style' },
                { id: 'hairLength', value: 'Short', displayName: 'Hair Length' },
                { id: 'hairColor', value: 'Brown', displayName: 'Hair Color' },
                { id: 'bodyType', value: 'Lean', displayName: 'Body Type' },
                { id: 'breastSize', value: 'N/A', displayName: 'Breast Size' },
                { id: 'buttSize', value: 'N/A', displayName: 'Butt Size' },
                { id: 'hobbies', value: 'Gaming', displayName: 'Hobbies' },
                { id: 'personality', value: 'Friendly', displayName: 'Personality of your AI' },
                { id: 'occupation', value: 'Software Developer', displayName: 'Occupation' }
            ],
            pictures: {
                title: 'Charen\'s Photos',
                text: 'Charen is always online and loves to share his moments. Check out his photos!',
                images: [
                    { src: require('@/assets/img/photos/photo2.png'), dateAdded: '2024-07-02' },
                    { src: require('@/assets/img/photos/photo1.png'), dateAdded: '2024-07-01' },
                    { src: require('@/assets/img/photos/photo3.png'), dateAdded: '2024-07-03' },
                    { src: require('@/assets/img/photos/photo4.png'), dateAdded: '2024-07-04' },
                    { src: require('@/assets/img/photos/photo5.png'), dateAdded: '2024-07-05' },
                    { src: require('@/assets/img/photos/photo2.png'), dateAdded: '2024-07-06' }
                ],
            },
            audioFiles: [
                { src: 'https://www.soundhelix.com/examples/mp3/SoundHelix-Song-1.mp3', type: 'audio/mpeg' },
                { src: 'https://www.soundhelix.com/examples/mp3/SoundHelix-Song-2.mp3', type: 'audio/mpeg' }
            ]
        },
        {
            id: 13,
            name: 'Olivia',
            badgeText: 'Online',
            description: 'Olivia is always online and loves connecting with others. He has a friendly and engaging personality.',
            profileImg: require('@/assets/img/cards/card.png'),
            avatar: require('@/assets/img/cards/avatar.png'),
            attributes: [
                { id: 'ethnicity', value: 'Asian', displayName: 'Ethnicity' },
                { id: 'age', value: '26', displayName: 'Age' },
                { id: 'eye', value: 'Black', displayName: 'Eye Color' },
                { id: 'hairStyle', value: 'Spiky', displayName: 'Hair Style' },
                { id: 'hairLength', value: 'Short', displayName: 'Hair Length' },
                { id: 'hairColor', value: 'Brown', displayName: 'Hair Color' },
                { id: 'bodyType', value: 'Lean', displayName: 'Body Type' },
                { id: 'breastSize', value: 'N/A', displayName: 'Breast Size' },
                { id: 'buttSize', value: 'N/A', displayName: 'Butt Size' },
                { id: 'hobbies', value: 'Gaming', displayName: 'Hobbies' },
                { id: 'personality', value: 'Friendly', displayName: 'Personality of your AI' },
                { id: 'occupation', value: 'Software Developer', displayName: 'Occupation' }
            ],
            pictures: {
                title: 'Olivia\'s Photos',
                text: 'Olivia is always online and loves to share his moments. Check out his photos!',
                images: [
                    { src: require('@/assets/img/photos/photo2.png'), dateAdded: '2024-07-02' },
                    { src: require('@/assets/img/photos/photo1.png'), dateAdded: '2024-07-01' },
                    { src: require('@/assets/img/photos/photo3.png'), dateAdded: '2024-07-03' },
                    { src: require('@/assets/img/photos/photo4.png'), dateAdded: '2024-07-04' },
                    { src: require('@/assets/img/photos/photo5.png'), dateAdded: '2024-07-05' },
                    { src: require('@/assets/img/photos/photo2.png'), dateAdded: '2024-07-06' }
                ],
            },
            audioFiles: [
                { src: 'https://www.soundhelix.com/examples/mp3/SoundHelix-Song-1.mp3', type: 'audio/mpeg' },
                { src: 'https://www.soundhelix.com/examples/mp3/SoundHelix-Song-2.mp3', type: 'audio/mpeg' }
            ]
        },
        {
            id: 14,
            name: 'Maria',
            badgeText: 'Online',
            description: 'Maria is always online and loves connecting with others. He has a friendly and engaging personality.',
            profileImg: require('@/assets/img/cards/card.png'),
            avatar: require('@/assets/img/cards/avatar.png'),
            attributes: [
                { id: 'ethnicity', value: 'Asian', displayName: 'Ethnicity' },
                { id: 'age', value: '26', displayName: 'Age' },
                { id: 'eye', value: 'Black', displayName: 'Eye Color' },
                { id: 'hairStyle', value: 'Spiky', displayName: 'Hair Style' },
                { id: 'hairLength', value: 'Short', displayName: 'Hair Length' },
                { id: 'hairColor', value: 'Brown', displayName: 'Hair Color' },
                { id: 'bodyType', value: 'Lean', displayName: 'Body Type' },
                { id: 'breastSize', value: 'N/A', displayName: 'Breast Size' },
                { id: 'buttSize', value: 'N/A', displayName: 'Butt Size' },
                { id: 'hobbies', value: 'Gaming', displayName: 'Hobbies' },
                { id: 'personality', value: 'Friendly', displayName: 'Personality of your AI' },
                { id: 'occupation', value: 'Software Developer', displayName: 'Occupation' }
            ],
            pictures: {
                title: 'Maria\'s Photos',
                text: 'Maria is always online and loves to share his moments. Check out his photos!',
                images: [
                    { src: require('@/assets/img/photos/photo2.png'), dateAdded: '2024-07-02' },
                    { src: require('@/assets/img/photos/photo1.png'), dateAdded: '2024-07-01' },
                    { src: require('@/assets/img/photos/photo3.png'), dateAdded: '2024-07-03' },
                    { src: require('@/assets/img/photos/photo4.png'), dateAdded: '2024-07-04' },
                    { src: require('@/assets/img/photos/photo5.png'), dateAdded: '2024-07-05' },
                    { src: require('@/assets/img/photos/photo2.png'), dateAdded: '2024-07-06' }
                ],
            },
            audioFiles: [
                { src: 'https://www.soundhelix.com/examples/mp3/SoundHelix-Song-1.mp3', type: 'audio/mpeg' },
                { src: 'https://www.soundhelix.com/examples/mp3/SoundHelix-Song-2.mp3', type: 'audio/mpeg' }
            ]
        },
        {
            id: 15,
            name: 'Iren',
            badgeText: 'Online',
            description: 'Iren is always online and loves connecting with others. He has a friendly and engaging personality.',
            profileImg: require('@/assets/img/cards/card.png'),
            avatar: require('@/assets/img/cards/avatar.png'),
            attributes: [
                { id: 'ethnicity', value: 'Asian', displayName: 'Ethnicity' },
                { id: 'age', value: '26', displayName: 'Age' },
                { id: 'eye', value: 'Black', displayName: 'Eye Color' },
                { id: 'hairStyle', value: 'Spiky', displayName: 'Hair Style' },
                { id: 'hairLength', value: 'Short', displayName: 'Hair Length' },
                { id: 'hairColor', value: 'Brown', displayName: 'Hair Color' },
                { id: 'bodyType', value: 'Lean', displayName: 'Body Type' },
                { id: 'breastSize', value: 'N/A', displayName: 'Breast Size' },
                { id: 'buttSize', value: 'N/A', displayName: 'Butt Size' },
                { id: 'hobbies', value: 'Gaming', displayName: 'Hobbies' },
                { id: 'personality', value: 'Friendly', displayName: 'Personality of your AI' },
                { id: 'occupation', value: 'Software Developer', displayName: 'Occupation' }
            ],
            pictures: {
                title: 'Iren\'s Photos',
                text: 'Iren is always online and loves to share his moments. Check out his photos!',
                images: [
                    { src: require('@/assets/img/photos/photo2.png'), dateAdded: '2024-07-02' },
                    { src: require('@/assets/img/photos/photo1.png'), dateAdded: '2024-07-01' },
                    { src: require('@/assets/img/photos/photo3.png'), dateAdded: '2024-07-03' },
                    { src: require('@/assets/img/photos/photo4.png'), dateAdded: '2024-07-04' },
                    { src: require('@/assets/img/photos/photo5.png'), dateAdded: '2024-07-05' },
                    { src: require('@/assets/img/photos/photo2.png'), dateAdded: '2024-07-06' }
                ],
            },
            audioFiles: [
                { src: 'https://www.soundhelix.com/examples/mp3/SoundHelix-Song-1.mp3', type: 'audio/mpeg' },
                { src: 'https://www.soundhelix.com/examples/mp3/SoundHelix-Song-2.mp3', type: 'audio/mpeg' }
            ]
        },
        {
            id: 16,
            name: 'Bobola',
            badgeText: 'Online',
            description: 'Bobola is always online and loves connecting with others. He has a friendly and engaging personality.',
            profileImg: require('@/assets/img/cards/card.png'),
            avatar: require('@/assets/img/cards/avatar.png'),
            attributes: [
                { id: 'ethnicity', value: 'Asian', displayName: 'Ethnicity' },
                { id: 'age', value: '26', displayName: 'Age' },
                { id: 'eye', value: 'Black', displayName: 'Eye Color' },
                { id: 'hairStyle', value: 'Spiky', displayName: 'Hair Style' },
                { id: 'hairLength', value: 'Short', displayName: 'Hair Length' },
                { id: 'hairColor', value: 'Brown', displayName: 'Hair Color' },
                { id: 'bodyType', value: 'Lean', displayName: 'Body Type' },
                { id: 'breastSize', value: 'N/A', displayName: 'Breast Size' },
                { id: 'buttSize', value: 'N/A', displayName: 'Butt Size' },
                { id: 'hobbies', value: 'Gaming', displayName: 'Hobbies' },
                { id: 'personality', value: 'Friendly', displayName: 'Personality of your AI' },
                { id: 'occupation', value: 'Software Developer', displayName: 'Occupation' }
            ],
            pictures: {
                title: 'Bobola\'s Photos',
                text: 'Bobola is always online and loves to share his moments. Check out his photos!',
                images: [
                    { src: require('@/assets/img/photos/photo2.png'), dateAdded: '2024-07-02' },
                    { src: require('@/assets/img/photos/photo1.png'), dateAdded: '2024-07-01' },
                    { src: require('@/assets/img/photos/photo3.png'), dateAdded: '2024-07-03' },
                    { src: require('@/assets/img/photos/photo4.png'), dateAdded: '2024-07-04' },
                    { src: require('@/assets/img/photos/photo5.png'), dateAdded: '2024-07-05' },
                    { src: require('@/assets/img/photos/photo2.png'), dateAdded: '2024-07-06' }
                ],
            },
            audioFiles: [
                { src: 'https://www.soundhelix.com/examples/mp3/SoundHelix-Song-1.mp3', type: 'audio/mpeg' },
                { src: 'https://www.soundhelix.com/examples/mp3/SoundHelix-Song-2.mp3', type: 'audio/mpeg' }
            ]
        },
        {
            id: 17,
            name: 'Snako',
            badgeText: 'Online',
            description: 'Snako is always online and loves connecting with others. He has a friendly and engaging personality.',
            profileImg: require('@/assets/img/cards/card.png'),
            avatar: require('@/assets/img/cards/avatar.png'),
            attributes: [
                { id: 'ethnicity', value: 'Asian', displayName: 'Ethnicity' },
                { id: 'age', value: '26', displayName: 'Age' },
                { id: 'eye', value: 'Black', displayName: 'Eye Color' },
                { id: 'hairStyle', value: 'Spiky', displayName: 'Hair Style' },
                { id: 'hairLength', value: 'Short', displayName: 'Hair Length' },
                { id: 'hairColor', value: 'Brown', displayName: 'Hair Color' },
                { id: 'bodyType', value: 'Lean', displayName: 'Body Type' },
                { id: 'breastSize', value: 'N/A', displayName: 'Breast Size' },
                { id: 'buttSize', value: 'N/A', displayName: 'Butt Size' },
                { id: 'hobbies', value: 'Gaming', displayName: 'Hobbies' },
                { id: 'personality', value: 'Friendly', displayName: 'Personality of your AI' },
                { id: 'occupation', value: 'Software Developer', displayName: 'Occupation' }
            ],
            pictures: {
                title: 'Snako\'s Photos',
                text: 'Snako is always online and loves to share his moments. Check out his photos!',
                images: [
                    { src: require('@/assets/img/photos/photo2.png'), dateAdded: '2024-07-02' },
                    { src: require('@/assets/img/photos/photo1.png'), dateAdded: '2024-07-01' },
                    { src: require('@/assets/img/photos/photo3.png'), dateAdded: '2024-07-03' },
                    { src: require('@/assets/img/photos/photo4.png'), dateAdded: '2024-07-04' },
                    { src: require('@/assets/img/photos/photo5.png'), dateAdded: '2024-07-05' },
                    { src: require('@/assets/img/photos/photo2.png'), dateAdded: '2024-07-06' }
                ],
            },
            audioFiles: [
                { src: 'https://www.soundhelix.com/examples/mp3/SoundHelix-Song-1.mp3', type: 'audio/mpeg' },
                { src: 'https://www.soundhelix.com/examples/mp3/SoundHelix-Song-2.mp3', type: 'audio/mpeg' }
            ]
        },
        {
            id: 18,
            name: 'Waka',
            badgeText: 'Online',
            description: 'Waka is always online and loves connecting with others. He has a friendly and engaging personality.',
            profileImg: require('@/assets/img/cards/card.png'),
            avatar: require('@/assets/img/cards/avatar.png'),
            attributes: [
                { id: 'ethnicity', value: 'Asian', displayName: 'Ethnicity' },
                { id: 'age', value: '26', displayName: 'Age' },
                { id: 'eye', value: 'Black', displayName: 'Eye Color' },
                { id: 'hairStyle', value: 'Spiky', displayName: 'Hair Style' },
                { id: 'hairLength', value: 'Short', displayName: 'Hair Length' },
                { id: 'hairColor', value: 'Brown', displayName: 'Hair Color' },
                { id: 'bodyType', value: 'Lean', displayName: 'Body Type' },
                { id: 'breastSize', value: 'N/A', displayName: 'Breast Size' },
                { id: 'buttSize', value: 'N/A', displayName: 'Butt Size' },
                { id: 'hobbies', value: 'Gaming', displayName: 'Hobbies' },
                { id: 'personality', value: 'Friendly', displayName: 'Personality of your AI' },
                { id: 'occupation', value: 'Software Developer', displayName: 'Occupation' }
            ],
            pictures: {
                title: 'Waka\'s Photos',
                text: 'Waka is always online and loves to share his moments. Check out his photos!',
                images: [
                    { src: require('@/assets/img/photos/photo2.png'), dateAdded: '2024-07-02' },
                    { src: require('@/assets/img/photos/photo1.png'), dateAdded: '2024-07-01' },
                    { src: require('@/assets/img/photos/photo3.png'), dateAdded: '2024-07-03' },
                    { src: require('@/assets/img/photos/photo4.png'), dateAdded: '2024-07-04' },
                    { src: require('@/assets/img/photos/photo5.png'), dateAdded: '2024-07-05' },
                    { src: require('@/assets/img/photos/photo2.png'), dateAdded: '2024-07-06' }
                ],
            },
            audioFiles: [
                { src: 'https://www.soundhelix.com/examples/mp3/SoundHelix-Song-1.mp3', type: 'audio/mpeg' },
                { src: 'https://www.soundhelix.com/examples/mp3/SoundHelix-Song-2.mp3', type: 'audio/mpeg' }
            ]
        },
        {
            id: 19,
            name: 'Maka',
            badgeText: 'Online',
            description: 'Maka is always online and loves connecting with others. He has a friendly and engaging personality.',
            profileImg: require('@/assets/img/cards/card.png'),
            avatar: require('@/assets/img/cards/avatar.png'),
            attributes: [
                { id: 'ethnicity', value: 'Asian', displayName: 'Ethnicity' },
                { id: 'age', value: '26', displayName: 'Age' },
                { id: 'eye', value: 'Black', displayName: 'Eye Color' },
                { id: 'hairStyle', value: 'Spiky', displayName: 'Hair Style' },
                { id: 'hairLength', value: 'Short', displayName: 'Hair Length' },
                { id: 'hairColor', value: 'Brown', displayName: 'Hair Color' },
                { id: 'bodyType', value: 'Lean', displayName: 'Body Type' },
                { id: 'breastSize', value: 'N/A', displayName: 'Breast Size' },
                { id: 'buttSize', value: 'N/A', displayName: 'Butt Size' },
                { id: 'hobbies', value: 'Gaming', displayName: 'Hobbies' },
                { id: 'personality', value: 'Friendly', displayName: 'Personality of your AI' },
                { id: 'occupation', value: 'Software Developer', displayName: 'Occupation' }
            ],
            pictures: {
                title: 'Maka\'s Photos',
                text: 'Maka is always online and loves to share his moments. Check out his photos!',
                images: [
                    { src: require('@/assets/img/photos/photo2.png'), dateAdded: '2024-07-02' },
                    { src: require('@/assets/img/photos/photo1.png'), dateAdded: '2024-07-01' },
                    { src: require('@/assets/img/photos/photo3.png'), dateAdded: '2024-07-03' },
                    { src: require('@/assets/img/photos/photo4.png'), dateAdded: '2024-07-04' },
                    { src: require('@/assets/img/photos/photo5.png'), dateAdded: '2024-07-05' },
                    { src: require('@/assets/img/photos/photo2.png'), dateAdded: '2024-07-06' }
                ],
            },
            audioFiles: [
                { src: 'https://www.soundhelix.com/examples/mp3/SoundHelix-Song-1.mp3', type: 'audio/mpeg' },
                { src: 'https://www.soundhelix.com/examples/mp3/SoundHelix-Song-2.mp3', type: 'audio/mpeg' }
            ]
        },
        {
            id: 20,
            name: 'Lala',
            badgeText: 'Online',
            description: 'Lala is always online and loves connecting with others. He has a friendly and engaging personality.',
            profileImg: require('@/assets/img/cards/card.png'),
            avatar: require('@/assets/img/cards/avatar.png'),
            attributes: [
                { id: 'ethnicity', value: 'Asian', displayName: 'Ethnicity' },
                { id: 'age', value: '26', displayName: 'Age' },
                { id: 'eye', value: 'Black', displayName: 'Eye Color' },
                { id: 'hairStyle', value: 'Spiky', displayName: 'Hair Style' },
                { id: 'hairLength', value: 'Short', displayName: 'Hair Length' },
                { id: 'hairColor', value: 'Brown', displayName: 'Hair Color' },
                { id: 'bodyType', value: 'Lean', displayName: 'Body Type' },
                { id: 'breastSize', value: 'N/A', displayName: 'Breast Size' },
                { id: 'buttSize', value: 'N/A', displayName: 'Butt Size' },
                { id: 'hobbies', value: 'Gaming', displayName: 'Hobbies' },
                { id: 'personality', value: 'Friendly', displayName: 'Personality of your AI' },
                { id: 'occupation', value: 'Software Developer', displayName: 'Occupation' }
            ],
            pictures: {
                title: 'Lala\'s Photos',
                text: 'Lala is always online and loves to share his moments. Check out his photos!',
                images: [
                    { src: require('@/assets/img/photos/photo2.png'), dateAdded: '2024-07-02' },
                    { src: require('@/assets/img/photos/photo1.png'), dateAdded: '2024-07-01' },
                    { src: require('@/assets/img/photos/photo3.png'), dateAdded: '2024-07-03' },
                    { src: require('@/assets/img/photos/photo4.png'), dateAdded: '2024-07-04' },
                    { src: require('@/assets/img/photos/photo5.png'), dateAdded: '2024-07-05' },
                    { src: require('@/assets/img/photos/photo2.png'), dateAdded: '2024-07-06' }
                ],
            },
            audioFiles: [
                { src: 'https://www.soundhelix.com/examples/mp3/SoundHelix-Song-1.mp3', type: 'audio/mpeg' },
                { src: 'https://www.soundhelix.com/examples/mp3/SoundHelix-Song-2.mp3', type: 'audio/mpeg' }
            ]
        },
        {
            id: 21,
            name: 'Puta',
            badgeText: 'Online',
            description: 'Puta is always online and loves connecting with others. He has a friendly and engaging personality.',
            profileImg: require('@/assets/img/cards/card.png'),
            avatar: require('@/assets/img/cards/avatar.png'),
            attributes: [
                { id: 'ethnicity', value: 'Asian', displayName: 'Ethnicity' },
                { id: 'age', value: '26', displayName: 'Age' },
                { id: 'eye', value: 'Black', displayName: 'Eye Color' },
                { id: 'hairStyle', value: 'Spiky', displayName: 'Hair Style' },
                { id: 'hairLength', value: 'Short', displayName: 'Hair Length' },
                { id: 'hairColor', value: 'Brown', displayName: 'Hair Color' },
                { id: 'bodyType', value: 'Lean', displayName: 'Body Type' },
                { id: 'breastSize', value: 'N/A', displayName: 'Breast Size' },
                { id: 'buttSize', value: 'N/A', displayName: 'Butt Size' },
                { id: 'hobbies', value: 'Gaming', displayName: 'Hobbies' },
                { id: 'personality', value: 'Friendly', displayName: 'Personality of your AI' },
                { id: 'occupation', value: 'Software Developer', displayName: 'Occupation' }
            ],
            pictures: {
                title: 'Puta\'s Photos',
                text: 'Puta is always online and loves to share his moments. Check out his photos!',
                images: [
                    { src: require('@/assets/img/photos/photo2.png'), dateAdded: '2024-07-02' },
                    { src: require('@/assets/img/photos/photo1.png'), dateAdded: '2024-07-01' },
                    { src: require('@/assets/img/photos/photo3.png'), dateAdded: '2024-07-03' },
                    { src: require('@/assets/img/photos/photo4.png'), dateAdded: '2024-07-04' },
                    { src: require('@/assets/img/photos/photo5.png'), dateAdded: '2024-07-05' },
                    { src: require('@/assets/img/photos/photo2.png'), dateAdded: '2024-07-06' }
                ],
            },
            audioFiles: [
                { src: 'https://www.soundhelix.com/examples/mp3/SoundHelix-Song-1.mp3', type: 'audio/mpeg' },
                { src: 'https://www.soundhelix.com/examples/mp3/SoundHelix-Song-2.mp3', type: 'audio/mpeg' }
            ]
        },
        {
            id: 22,
            name: 'Robot',
            badgeText: 'Online',
            description: 'Robot is always online and loves connecting with others. He has a friendly and engaging personality.',
            profileImg: require('@/assets/img/cards/card.png'),
            avatar: require('@/assets/img/cards/avatar.png'),
            attributes: [
                { id: 'ethnicity', value: 'Asian', displayName: 'Ethnicity' },
                { id: 'age', value: '26', displayName: 'Age' },
                { id: 'eye', value: 'Black', displayName: 'Eye Color' },
                { id: 'hairStyle', value: 'Spiky', displayName: 'Hair Style' },
                { id: 'hairLength', value: 'Short', displayName: 'Hair Length' },
                { id: 'hairColor', value: 'Brown', displayName: 'Hair Color' },
                { id: 'bodyType', value: 'Lean', displayName: 'Body Type' },
                { id: 'breastSize', value: 'N/A', displayName: 'Breast Size' },
                { id: 'buttSize', value: 'N/A', displayName: 'Butt Size' },
                { id: 'hobbies', value: 'Gaming', displayName: 'Hobbies' },
                { id: 'personality', value: 'Friendly', displayName: 'Personality of your AI' },
                { id: 'occupation', value: 'Software Developer', displayName: 'Occupation' }
            ],
            pictures: {
                title: 'Robot\'s Photos',
                text: 'Robot is always online and loves to share his moments. Check out his photos!',
                images: [
                    { src: require('@/assets/img/photos/photo2.png'), dateAdded: '2024-07-02' },
                    { src: require('@/assets/img/photos/photo1.png'), dateAdded: '2024-07-01' },
                    { src: require('@/assets/img/photos/photo3.png'), dateAdded: '2024-07-03' },
                    { src: require('@/assets/img/photos/photo4.png'), dateAdded: '2024-07-04' },
                    { src: require('@/assets/img/photos/photo5.png'), dateAdded: '2024-07-05' },
                    { src: require('@/assets/img/photos/photo2.png'), dateAdded: '2024-07-06' }
                ],
            },
            audioFiles: [
                { src: 'https://www.soundhelix.com/examples/mp3/SoundHelix-Song-1.mp3', type: 'audio/mpeg' },
                { src: 'https://www.soundhelix.com/examples/mp3/SoundHelix-Song-2.mp3', type: 'audio/mpeg' }
            ]
        },
        {
            id: 23,
            name: 'Maki',
            badgeText: 'Online',
            description: 'Maki is always online and loves connecting with others. He has a friendly and engaging personality.',
            profileImg: require('@/assets/img/cards/card.png'),
            avatar: require('@/assets/img/cards/avatar.png'),
            attributes: [
                { id: 'ethnicity', value: 'Asian', displayName: 'Ethnicity' },
                { id: 'age', value: '26', displayName: 'Age' },
                { id: 'eye', value: 'Black', displayName: 'Eye Color' },
                { id: 'hairStyle', value: 'Spiky', displayName: 'Hair Style' },
                { id: 'hairLength', value: 'Short', displayName: 'Hair Length' },
                { id: 'hairColor', value: 'Brown', displayName: 'Hair Color' },
                { id: 'bodyType', value: 'Lean', displayName: 'Body Type' },
                { id: 'breastSize', value: 'N/A', displayName: 'Breast Size' },
                { id: 'buttSize', value: 'N/A', displayName: 'Butt Size' },
                { id: 'hobbies', value: 'Gaming', displayName: 'Hobbies' },
                { id: 'personality', value: 'Friendly', displayName: 'Personality of your AI' },
                { id: 'occupation', value: 'Software Developer', displayName: 'Occupation' }
            ],
            pictures: {
                title: 'Maki\'s Photos',
                text: 'Maki is always online and loves to share his moments. Check out his photos!',
                images: [
                    { src: require('@/assets/img/photos/photo2.png'), dateAdded: '2024-07-02' },
                    { src: require('@/assets/img/photos/photo1.png'), dateAdded: '2024-07-01' },
                    { src: require('@/assets/img/photos/photo3.png'), dateAdded: '2024-07-03' },
                    { src: require('@/assets/img/photos/photo4.png'), dateAdded: '2024-07-04' },
                    { src: require('@/assets/img/photos/photo5.png'), dateAdded: '2024-07-05' },
                    { src: require('@/assets/img/photos/photo2.png'), dateAdded: '2024-07-06' }
                ],
            },
            audioFiles: [
                { src: 'https://www.soundhelix.com/examples/mp3/SoundHelix-Song-1.mp3', type: 'audio/mpeg' },
                { src: 'https://www.soundhelix.com/examples/mp3/SoundHelix-Song-2.mp3', type: 'audio/mpeg' }
            ]
        },
        {
            id: 24,
            name: 'Lalafa',
            badgeText: 'Online',
            description: 'Lalafa is always online and loves connecting with others. He has a friendly and engaging personality.',
            profileImg: require('@/assets/img/cards/card.png'),
            avatar: require('@/assets/img/cards/avatar.png'),
            attributes: [
                { id: 'ethnicity', value: 'Asian', displayName: 'Ethnicity' },
                { id: 'age', value: '26', displayName: 'Age' },
                { id: 'eye', value: 'Black', displayName: 'Eye Color' },
                { id: 'hairStyle', value: 'Spiky', displayName: 'Hair Style' },
                { id: 'hairLength', value: 'Short', displayName: 'Hair Length' },
                { id: 'hairColor', value: 'Brown', displayName: 'Hair Color' },
                { id: 'bodyType', value: 'Lean', displayName: 'Body Type' },
                { id: 'breastSize', value: 'N/A', displayName: 'Breast Size' },
                { id: 'buttSize', value: 'N/A', displayName: 'Butt Size' },
                { id: 'hobbies', value: 'Gaming', displayName: 'Hobbies' },
                { id: 'personality', value: 'Friendly', displayName: 'Personality of your AI' },
                { id: 'occupation', value: 'Software Developer', displayName: 'Occupation' }
            ],
            pictures: {
                title: 'Lalafa\'s Photos',
                text: 'Lalafa is always online and loves to share his moments. Check out his photos!',
                images: [
                    { src: require('@/assets/img/photos/photo2.png'), dateAdded: '2024-07-02' },
                    { src: require('@/assets/img/photos/photo1.png'), dateAdded: '2024-07-01' },
                    { src: require('@/assets/img/photos/photo3.png'), dateAdded: '2024-07-03' },
                    { src: require('@/assets/img/photos/photo4.png'), dateAdded: '2024-07-04' },
                    { src: require('@/assets/img/photos/photo5.png'), dateAdded: '2024-07-05' },
                    { src: require('@/assets/img/photos/photo2.png'), dateAdded: '2024-07-06' }
                ],
            },
            audioFiles: [
                { src: 'https://www.soundhelix.com/examples/mp3/SoundHelix-Song-1.mp3', type: 'audio/mpeg' },
                { src: 'https://www.soundhelix.com/examples/mp3/SoundHelix-Song-2.mp3', type: 'audio/mpeg' }
            ]
        },
        {
            id: 25,
            name: 'Bobr',
            badgeText: 'Online',
            description: 'Bobr is always online and loves connecting with others. He has a friendly and engaging personality.',
            profileImg: require('@/assets/img/cards/card.png'),
            avatar: require('@/assets/img/cards/avatar.png'),
            attributes: [
                { id: 'ethnicity', value: 'Asian', displayName: 'Ethnicity' },
                { id: 'age', value: '26', displayName: 'Age' },
                { id: 'eye', value: 'Black', displayName: 'Eye Color' },
                { id: 'hairStyle', value: 'Spiky', displayName: 'Hair Style' },
                { id: 'hairLength', value: 'Short', displayName: 'Hair Length' },
                { id: 'hairColor', value: 'Brown', displayName: 'Hair Color' },
                { id: 'bodyType', value: 'Lean', displayName: 'Body Type' },
                { id: 'breastSize', value: 'N/A', displayName: 'Breast Size' },
                { id: 'buttSize', value: 'N/A', displayName: 'Butt Size' },
                { id: 'hobbies', value: 'Gaming', displayName: 'Hobbies' },
                { id: 'personality', value: 'Friendly', displayName: 'Personality of your AI' },
                { id: 'occupation', value: 'Software Developer', displayName: 'Occupation' }
            ],
            pictures: {
                title: 'Bobr\'s Photos',
                text: 'Bobr is always online and loves to share his moments. Check out his photos!',
                images: [
                    { src: require('@/assets/img/photos/photo2.png'), dateAdded: '2024-07-02' },
                    { src: require('@/assets/img/photos/photo1.png'), dateAdded: '2024-07-01' },
                    { src: require('@/assets/img/photos/photo3.png'), dateAdded: '2024-07-03' },
                    { src: require('@/assets/img/photos/photo4.png'), dateAdded: '2024-07-04' },
                    { src: require('@/assets/img/photos/photo5.png'), dateAdded: '2024-07-05' },
                    { src: require('@/assets/img/photos/photo2.png'), dateAdded: '2024-07-06' }
                ],
            },
            audioFiles: [
                { src: 'https://www.soundhelix.com/examples/mp3/SoundHelix-Song-1.mp3', type: 'audio/mpeg' },
                { src: 'https://www.soundhelix.com/examples/mp3/SoundHelix-Song-2.mp3', type: 'audio/mpeg' }
            ]
        },
        {
            id: 26,
            name: 'Winki',
            badgeText: 'Online',
            description: 'Winki is always online and loves connecting with others. He has a friendly and engaging personality.',
            profileImg: require('@/assets/img/cards/card.png'),
            avatar: require('@/assets/img/cards/avatar.png'),
            attributes: [
                { id: 'ethnicity', value: 'Asian', displayName: 'Ethnicity' },
                { id: 'age', value: '26', displayName: 'Age' },
                { id: 'eye', value: 'Black', displayName: 'Eye Color' },
                { id: 'hairStyle', value: 'Spiky', displayName: 'Hair Style' },
                { id: 'hairLength', value: 'Short', displayName: 'Hair Length' },
                { id: 'hairColor', value: 'Brown', displayName: 'Hair Color' },
                { id: 'bodyType', value: 'Lean', displayName: 'Body Type' },
                { id: 'breastSize', value: 'N/A', displayName: 'Breast Size' },
                { id: 'buttSize', value: 'N/A', displayName: 'Butt Size' },
                { id: 'hobbies', value: 'Gaming', displayName: 'Hobbies' },
                { id: 'personality', value: 'Friendly', displayName: 'Personality of your AI' },
                { id: 'occupation', value: 'Software Developer', displayName: 'Occupation' }
            ],
            pictures: {
                title: 'Winki\'s Photos',
                text: 'Winki is always online and loves to share his moments. Check out his photos!',
                images: [
                    { src: require('@/assets/img/photos/photo2.png'), dateAdded: '2024-07-02' },
                    { src: require('@/assets/img/photos/photo1.png'), dateAdded: '2024-07-01' },
                    { src: require('@/assets/img/photos/photo3.png'), dateAdded: '2024-07-03' },
                    { src: require('@/assets/img/photos/photo4.png'), dateAdded: '2024-07-04' },
                    { src: require('@/assets/img/photos/photo5.png'), dateAdded: '2024-07-05' },
                    { src: require('@/assets/img/photos/photo2.png'), dateAdded: '2024-07-06' }
                ],
            },
            audioFiles: [
                { src: 'https://www.soundhelix.com/examples/mp3/SoundHelix-Song-1.mp3', type: 'audio/mpeg' },
                { src: 'https://www.soundhelix.com/examples/mp3/SoundHelix-Song-2.mp3', type: 'audio/mpeg' }
            ]
        },
        {
            id: 27,
            name: 'Tinki',
            badgeText: 'Online',
            description: 'Tinki is always online and loves connecting with others. He has a friendly and engaging personality.',
            profileImg: require('@/assets/img/cards/card.png'),
            avatar: require('@/assets/img/cards/avatar.png'),
            attributes: [
                { id: 'ethnicity', value: 'Asian', displayName: 'Ethnicity' },
                { id: 'age', value: '26', displayName: 'Age' },
                { id: 'eye', value: 'Black', displayName: 'Eye Color' },
                { id: 'hairStyle', value: 'Spiky', displayName: 'Hair Style' },
                { id: 'hairLength', value: 'Short', displayName: 'Hair Length' },
                { id: 'hairColor', value: 'Brown', displayName: 'Hair Color' },
                { id: 'bodyType', value: 'Lean', displayName: 'Body Type' },
                { id: 'breastSize', value: 'N/A', displayName: 'Breast Size' },
                { id: 'buttSize', value: 'N/A', displayName: 'Butt Size' },
                { id: 'hobbies', value: 'Gaming', displayName: 'Hobbies' },
                { id: 'personality', value: 'Friendly', displayName: 'Personality of your AI' },
                { id: 'occupation', value: 'Software Developer', displayName: 'Occupation' }
            ],
            pictures: {
                title: 'Tinki\'s Photos',
                text: 'Tinki is always online and loves to share his moments. Check out his photos!',
                images: [
                    { src: require('@/assets/img/photos/photo2.png'), dateAdded: '2024-07-02' },
                    { src: require('@/assets/img/photos/photo1.png'), dateAdded: '2024-07-01' },
                    { src: require('@/assets/img/photos/photo3.png'), dateAdded: '2024-07-03' },
                    { src: require('@/assets/img/photos/photo4.png'), dateAdded: '2024-07-04' },
                    { src: require('@/assets/img/photos/photo5.png'), dateAdded: '2024-07-05' },
                    { src: require('@/assets/img/photos/photo2.png'), dateAdded: '2024-07-06' }
                ],
            },
            audioFiles: [
                { src: 'https://www.soundhelix.com/examples/mp3/SoundHelix-Song-1.mp3', type: 'audio/mpeg' },
                { src: 'https://www.soundhelix.com/examples/mp3/SoundHelix-Song-2.mp3', type: 'audio/mpeg' }
            ]
        },
        {
            id: 28,
            name: 'Lala',
            badgeText: 'Online',
            description: 'Lala is always online and loves connecting with others. He has a friendly and engaging personality.',
            profileImg: require('@/assets/img/cards/card.png'),
            avatar: require('@/assets/img/cards/avatar.png'),
            attributes: [
                { id: 'ethnicity', value: 'Asian', displayName: 'Ethnicity' },
                { id: 'age', value: '26', displayName: 'Age' },
                { id: 'eye', value: 'Black', displayName: 'Eye Color' },
                { id: 'hairStyle', value: 'Spiky', displayName: 'Hair Style' },
                { id: 'hairLength', value: 'Short', displayName: 'Hair Length' },
                { id: 'hairColor', value: 'Brown', displayName: 'Hair Color' },
                { id: 'bodyType', value: 'Lean', displayName: 'Body Type' },
                { id: 'breastSize', value: 'N/A', displayName: 'Breast Size' },
                { id: 'buttSize', value: 'N/A', displayName: 'Butt Size' },
                { id: 'hobbies', value: 'Gaming', displayName: 'Hobbies' },
                { id: 'personality', value: 'Friendly', displayName: 'Personality of your AI' },
                { id: 'occupation', value: 'Software Developer', displayName: 'Occupation' }
            ],
            pictures: {
                title: 'Lala\'s Photos',
                text: 'Lala is always online and loves to share his moments. Check out his photos!',
                images: [
                    { src: require('@/assets/img/photos/photo2.png'), dateAdded: '2024-07-02' },
                    { src: require('@/assets/img/photos/photo1.png'), dateAdded: '2024-07-01' },
                    { src: require('@/assets/img/photos/photo3.png'), dateAdded: '2024-07-03' },
                    { src: require('@/assets/img/photos/photo4.png'), dateAdded: '2024-07-04' },
                    { src: require('@/assets/img/photos/photo5.png'), dateAdded: '2024-07-05' },
                    { src: require('@/assets/img/photos/photo2.png'), dateAdded: '2024-07-06' }
                ],
            },
            audioFiles: [
                { src: 'https://www.soundhelix.com/examples/mp3/SoundHelix-Song-1.mp3', type: 'audio/mpeg' },
                { src: 'https://www.soundhelix.com/examples/mp3/SoundHelix-Song-2.mp3', type: 'audio/mpeg' }
            ]
        },
        {
            id: 29,
            name: 'Ckopo',
            badgeText: 'Online',
            description: 'Ckopo is always online and loves connecting with others. He has a friendly and engaging personality.',
            profileImg: require('@/assets/img/cards/card.png'),
            avatar: require('@/assets/img/cards/avatar.png'),
            attributes: [
                { id: 'ethnicity', value: 'Asian', displayName: 'Ethnicity' },
                { id: 'age', value: '26', displayName: 'Age' },
                { id: 'eye', value: 'Black', displayName: 'Eye Color' },
                { id: 'hairStyle', value: 'Spiky', displayName: 'Hair Style' },
                { id: 'hairLength', value: 'Short', displayName: 'Hair Length' },
                { id: 'hairColor', value: 'Brown', displayName: 'Hair Color' },
                { id: 'bodyType', value: 'Lean', displayName: 'Body Type' },
                { id: 'breastSize', value: 'N/A', displayName: 'Breast Size' },
                { id: 'buttSize', value: 'N/A', displayName: 'Butt Size' },
                { id: 'hobbies', value: 'Gaming', displayName: 'Hobbies' },
                { id: 'personality', value: 'Friendly', displayName: 'Personality of your AI' },
                { id: 'occupation', value: 'Software Developer', displayName: 'Occupation' }
            ],
            pictures: {
                title: 'Ckopo\'s Photos',
                text: 'Ckopo is always online and loves to share his moments. Check out his photos!',
                images: [
                    { src: require('@/assets/img/photos/photo2.png'), dateAdded: '2024-07-02' },
                    { src: require('@/assets/img/photos/photo1.png'), dateAdded: '2024-07-01' },
                    { src: require('@/assets/img/photos/photo3.png'), dateAdded: '2024-07-03' },
                    { src: require('@/assets/img/photos/photo4.png'), dateAdded: '2024-07-04' },
                    { src: require('@/assets/img/photos/photo5.png'), dateAdded: '2024-07-05' },
                    { src: require('@/assets/img/photos/photo2.png'), dateAdded: '2024-07-06' }
                ],
            },
            audioFiles: [
                { src: 'https://www.soundhelix.com/examples/mp3/SoundHelix-Song-1.mp3', type: 'audio/mpeg' },
                { src: 'https://www.soundhelix.com/examples/mp3/SoundHelix-Song-2.mp3', type: 'audio/mpeg' }
            ]
        },
        {
            id: 30,
            name: 'Sosa',
            badgeText: 'Online',
            description: 'Sosa is always online and loves connecting with others. He has a friendly and engaging personality.',
            profileImg: require('@/assets/img/cards/card.png'),
            avatar: require('@/assets/img/cards/avatar.png'),
            attributes: [
                { id: 'ethnicity', value: 'Asian', displayName: 'Ethnicity' },
                { id: 'age', value: '26', displayName: 'Age' },
                { id: 'eye', value: 'Black', displayName: 'Eye Color' },
                { id: 'hairStyle', value: 'Spiky', displayName: 'Hair Style' },
                { id: 'hairLength', value: 'Short', displayName: 'Hair Length' },
                { id: 'hairColor', value: 'Brown', displayName: 'Hair Color' },
                { id: 'bodyType', value: 'Lean', displayName: 'Body Type' },
                { id: 'breastSize', value: 'N/A', displayName: 'Breast Size' },
                { id: 'buttSize', value: 'N/A', displayName: 'Butt Size' },
                { id: 'hobbies', value: 'Gaming', displayName: 'Hobbies' },
                { id: 'personality', value: 'Friendly', displayName: 'Personality of your AI' },
                { id: 'occupation', value: 'Software Developer', displayName: 'Occupation' }
            ],
            pictures: {
                title: 'Sosa\'s Photos',
                text: 'Sosa is always online and loves to share his moments. Check out his photos!',
                images: [
                    { src: require('@/assets/img/photos/photo2.png'), dateAdded: '2024-07-02' },
                    { src: require('@/assets/img/photos/photo1.png'), dateAdded: '2024-07-01' },
                    { src: require('@/assets/img/photos/photo3.png'), dateAdded: '2024-07-03' },
                    { src: require('@/assets/img/photos/photo4.png'), dateAdded: '2024-07-04' },
                    { src: require('@/assets/img/photos/photo5.png'), dateAdded: '2024-07-05' },
                    { src: require('@/assets/img/photos/photo2.png'), dateAdded: '2024-07-06' }
                ],
            },
            audioFiles: [
                { src: 'https://www.soundhelix.com/examples/mp3/SoundHelix-Song-1.mp3', type: 'audio/mpeg' },
                { src: 'https://www.soundhelix.com/examples/mp3/SoundHelix-Song-2.mp3', type: 'audio/mpeg' }
            ]
        },
        {
            id: 31,
            name: 'Bola',
            badgeText: 'Online',
            description: 'Bola is always online and loves connecting with others. He has a friendly and engaging personality.',
            profileImg: require('@/assets/img/cards/card.png'),
            avatar: require('@/assets/img/cards/avatar.png'),
            attributes: [
                { id: 'ethnicity', value: 'Asian', displayName: 'Ethnicity' },
                { id: 'age', value: '26', displayName: 'Age' },
                { id: 'eye', value: 'Black', displayName: 'Eye Color' },
                { id: 'hairStyle', value: 'Spiky', displayName: 'Hair Style' },
                { id: 'hairLength', value: 'Short', displayName: 'Hair Length' },
                { id: 'hairColor', value: 'Brown', displayName: 'Hair Color' },
                { id: 'bodyType', value: 'Lean', displayName: 'Body Type' },
                { id: 'breastSize', value: 'N/A', displayName: 'Breast Size' },
                { id: 'buttSize', value: 'N/A', displayName: 'Butt Size' },
                { id: 'hobbies', value: 'Gaming', displayName: 'Hobbies' },
                { id: 'personality', value: 'Friendly', displayName: 'Personality of your AI' },
                { id: 'occupation', value: 'Software Developer', displayName: 'Occupation' }
            ],
            pictures: {
                title: 'Bola\'s Photos',
                text: 'Bola is always online and loves to share his moments. Check out his photos!',
                images: [
                    { src: require('@/assets/img/photos/photo2.png'), dateAdded: '2024-07-02' },
                    { src: require('@/assets/img/photos/photo1.png'), dateAdded: '2024-07-01' },
                    { src: require('@/assets/img/photos/photo3.png'), dateAdded: '2024-07-03' },
                    { src: require('@/assets/img/photos/photo4.png'), dateAdded: '2024-07-04' },
                    { src: require('@/assets/img/photos/photo5.png'), dateAdded: '2024-07-05' },
                    { src: require('@/assets/img/photos/photo2.png'), dateAdded: '2024-07-06' }
                ],
            },
            audioFiles: [
                { src: 'https://www.soundhelix.com/examples/mp3/SoundHelix-Song-1.mp3', type: 'audio/mpeg' },
                { src: 'https://www.soundhelix.com/examples/mp3/SoundHelix-Song-2.mp3', type: 'audio/mpeg' }
            ]
        },
        {
            id: 32,
            name: 'Mala',
            badgeText: 'Online',
            description: 'Mala is always online and loves connecting with others. He has a friendly and engaging personality.',
            profileImg: require('@/assets/img/cards/card.png'),
            avatar: require('@/assets/img/cards/avatar.png'),
            attributes: [
                { id: 'ethnicity', value: 'Asian', displayName: 'Ethnicity' },
                { id: 'age', value: '26', displayName: 'Age' },
                { id: 'eye', value: 'Black', displayName: 'Eye Color' },
                { id: 'hairStyle', value: 'Spiky', displayName: 'Hair Style' },
                { id: 'hairLength', value: 'Short', displayName: 'Hair Length' },
                { id: 'hairColor', value: 'Brown', displayName: 'Hair Color' },
                { id: 'bodyType', value: 'Lean', displayName: 'Body Type' },
                { id: 'breastSize', value: 'N/A', displayName: 'Breast Size' },
                { id: 'buttSize', value: 'N/A', displayName: 'Butt Size' },
                { id: 'hobbies', value: 'Gaming', displayName: 'Hobbies' },
                { id: 'personality', value: 'Friendly', displayName: 'Personality of your AI' },
                { id: 'occupation', value: 'Software Developer', displayName: 'Occupation' }
            ],
            pictures: {
                title: 'Mala\'s Photos',
                text: 'Mala is always online and loves to share his moments. Check out his photos!',
                images: [
                    { src: require('@/assets/img/photos/photo2.png'), dateAdded: '2024-07-02' },
                    { src: require('@/assets/img/photos/photo1.png'), dateAdded: '2024-07-01' },
                    { src: require('@/assets/img/photos/photo3.png'), dateAdded: '2024-07-03' },
                    { src: require('@/assets/img/photos/photo4.png'), dateAdded: '2024-07-04' },
                    { src: require('@/assets/img/photos/photo5.png'), dateAdded: '2024-07-05' },
                    { src: require('@/assets/img/photos/photo2.png'), dateAdded: '2024-07-06' }
                ],
            },
            audioFiles: [
                { src: 'https://www.soundhelix.com/examples/mp3/SoundHelix-Song-1.mp3', type: 'audio/mpeg' },
                { src: 'https://www.soundhelix.com/examples/mp3/SoundHelix-Song-2.mp3', type: 'audio/mpeg' }
            ]
        },
        {
            id: 33,
            name: 'Kara',
            badgeText: 'Online',
            description: 'Kara is always online and loves connecting with others. He has a friendly and engaging personality.',
            profileImg: require('@/assets/img/cards/card.png'),
            avatar: require('@/assets/img/cards/avatar.png'),
            attributes: [
                { id: 'ethnicity', value: 'Asian', displayName: 'Ethnicity' },
                { id: 'age', value: '26', displayName: 'Age' },
                { id: 'eye', value: 'Black', displayName: 'Eye Color' },
                { id: 'hairStyle', value: 'Spiky', displayName: 'Hair Style' },
                { id: 'hairLength', value: 'Short', displayName: 'Hair Length' },
                { id: 'hairColor', value: 'Brown', displayName: 'Hair Color' },
                { id: 'bodyType', value: 'Lean', displayName: 'Body Type' },
                { id: 'breastSize', value: 'N/A', displayName: 'Breast Size' },
                { id: 'buttSize', value: 'N/A', displayName: 'Butt Size' },
                { id: 'hobbies', value: 'Gaming', displayName: 'Hobbies' },
                { id: 'personality', value: 'Friendly', displayName: 'Personality of your AI' },
                { id: 'occupation', value: 'Software Developer', displayName: 'Occupation' }
            ],
            pictures: {
                title: 'Kara\'s Photos',
                text: 'Kara is always online and loves to share his moments. Check out his photos!',
                images: [
                    { src: require('@/assets/img/photos/photo2.png'), dateAdded: '2024-07-02' },
                    { src: require('@/assets/img/photos/photo1.png'), dateAdded: '2024-07-01' },
                    { src: require('@/assets/img/photos/photo3.png'), dateAdded: '2024-07-03' },
                    { src: require('@/assets/img/photos/photo4.png'), dateAdded: '2024-07-04' },
                    { src: require('@/assets/img/photos/photo5.png'), dateAdded: '2024-07-05' },
                    { src: require('@/assets/img/photos/photo2.png'), dateAdded: '2024-07-06' }
                ],
            },
            audioFiles: [
                { src: 'https://www.soundhelix.com/examples/mp3/SoundHelix-Song-1.mp3', type: 'audio/mpeg' },
                { src: 'https://www.soundhelix.com/examples/mp3/SoundHelix-Song-2.mp3', type: 'audio/mpeg' }
            ]
        },
        {
            id: 34,
            name: 'Nata',
            badgeText: 'Online',
            description: 'Nata is always online and loves connecting with others. He has a friendly and engaging personality.',
            profileImg: require('@/assets/img/cards/card.png'),
            avatar: require('@/assets/img/cards/avatar.png'),
            attributes: [
                { id: 'ethnicity', value: 'Asian', displayName: 'Ethnicity' },
                { id: 'age', value: '26', displayName: 'Age' },
                { id: 'eye', value: 'Black', displayName: 'Eye Color' },
                { id: 'hairStyle', value: 'Spiky', displayName: 'Hair Style' },
                { id: 'hairLength', value: 'Short', displayName: 'Hair Length' },
                { id: 'hairColor', value: 'Brown', displayName: 'Hair Color' },
                { id: 'bodyType', value: 'Lean', displayName: 'Body Type' },
                { id: 'breastSize', value: 'N/A', displayName: 'Breast Size' },
                { id: 'buttSize', value: 'N/A', displayName: 'Butt Size' },
                { id: 'hobbies', value: 'Gaming', displayName: 'Hobbies' },
                { id: 'personality', value: 'Friendly', displayName: 'Personality of your AI' },
                { id: 'occupation', value: 'Software Developer', displayName: 'Occupation' }
            ],
            pictures: {
                title: 'Nata\'s Photos',
                text: 'Nata is always online and loves to share his moments. Check out his photos!',
                images: [
                    { src: require('@/assets/img/photos/photo2.png'), dateAdded: '2024-07-02' },
                    { src: require('@/assets/img/photos/photo1.png'), dateAdded: '2024-07-01' },
                    { src: require('@/assets/img/photos/photo3.png'), dateAdded: '2024-07-03' },
                    { src: require('@/assets/img/photos/photo4.png'), dateAdded: '2024-07-04' },
                    { src: require('@/assets/img/photos/photo5.png'), dateAdded: '2024-07-05' },
                    { src: require('@/assets/img/photos/photo2.png'), dateAdded: '2024-07-06' }
                ],
            },
            audioFiles: [
                { src: 'https://www.soundhelix.com/examples/mp3/SoundHelix-Song-1.mp3', type: 'audio/mpeg' },
                { src: 'https://www.soundhelix.com/examples/mp3/SoundHelix-Song-2.mp3', type: 'audio/mpeg' }
            ]
        }
    ];
};

export const getDefaultMembers = async () => {
    await new Promise(resolve => setTimeout(resolve, 1000));

    return [
        {
            id: 1,
            name: 'Masha',
            badgeText: 'New',
            description: 'Masha enjoys spending time in nature and has a passion for photography. She loves capturing beautiful moments with her camera.',
            profileImg: require('@/assets/img/cards/card.png'),
            avatar: require('@/assets/img/cards/avatar.png'),
            attributes: [
                { id: 'ethnicity', value: 'Caucasian', displayName: 'Ethnicity' },
                { id: 'age', value: '28', displayName: 'Age' },
                { id: 'eye', value: 'Blue', displayName: 'Eye Color' },
                { id: 'hairStyle', value: 'Straight', displayName: 'Hair Style' },
                { id: 'hairLength', value: 'Medium', displayName: 'Hair Length' },
                { id: 'hairColor', value: 'Blonde', displayName: 'Hair Color' },
                { id: 'bodyType', value: 'Slim', displayName: 'Body Type' },
                { id: 'breastSize', value: 'Small', displayName: 'Breast Size' },
                { id: 'buttSize', value: 'Small', displayName: 'Butt Size' },
                { id: 'hobbies', value: 'Photography', displayName: 'Hobbies' },
                { id: 'personality', value: 'Adventurous', displayName: 'Personality of your AI' },
                { id: 'occupation', value: 'Photographer', displayName: 'Occupation' }
            ],
            pictures: {
                title: 'Masha\'s Photos',
                text: 'Masha enjoys capturing beautiful moments. Check out her photos!',
                images: [
                    { src: require('@/assets/img/photos/photo2.png'), dateAdded: '2024-07-02' },
                    { src: require('@/assets/img/photos/photo1.png'), dateAdded: '2024-07-01' },
                    { src: require('@/assets/img/photos/photo3.png'), dateAdded: '2024-07-03' },
                    { src: require('@/assets/img/photos/photo4.png'), dateAdded: '2024-07-04' },
                    { src: require('@/assets/img/photos/photo5.png'), dateAdded: '2024-07-05' },
                    { src: require('@/assets/img/photos/photo2.png'), dateAdded: '2024-07-06' }
                ],
            },
            audioFiles: [
                { src: 'https://www.soundhelix.com/examples/mp3/SoundHelix-Song-1.mp3', type: 'audio/mpeg' },
                { src: 'https://www.soundhelix.com/examples/mp3/SoundHelix-Song-2.mp3', type: 'audio/mpeg' }
            ]
        },
        {
            id: 2,
            name: 'Natasha',
            badgeText: 'New',
            description: 'Natasha enjoys spending time in nature and has a passion for photography. She loves capturing beautiful moments with her camera.',
            profileImg: require('@/assets/img/cards/card.png'),
            avatar: require('@/assets/img/cards/avatar.png'),
            attributes: [
                { id: 'ethnicity', value: 'Caucasian', displayName: 'Ethnicity' },
                { id: 'age', value: '28', displayName: 'Age' },
                { id: 'eye', value: 'Blue', displayName: 'Eye Color' },
                { id: 'hairStyle', value: 'Straight', displayName: 'Hair Style' },
                { id: 'hairLength', value: 'Medium', displayName: 'Hair Length' },
                { id: 'hairColor', value: 'Blonde', displayName: 'Hair Color' },
                { id: 'bodyType', value: 'Slim', displayName: 'Body Type' },
                { id: 'breastSize', value: 'Small', displayName: 'Breast Size' },
                { id: 'buttSize', value: 'Small', displayName: 'Butt Size' },
                { id: 'hobbies', value: 'Photography', displayName: 'Hobbies' },
                { id: 'personality', value: 'Adventurous', displayName: 'Personality of your AI' },
                { id: 'occupation', value: 'Photographer', displayName: 'Occupation' }
            ],
            pictures: {
                title: 'Natasha\'s Photos',
                text: 'Natasha enjoys capturing beautiful moments. Check out her photos!',
                images: [
                    { src: require('@/assets/img/photos/photo2.png'), dateAdded: '2024-07-02' },
                    { src: require('@/assets/img/photos/photo1.png'), dateAdded: '2024-07-01' },
                    { src: require('@/assets/img/photos/photo3.png'), dateAdded: '2024-07-03' },
                    { src: require('@/assets/img/photos/photo4.png'), dateAdded: '2024-07-04' },
                    { src: require('@/assets/img/photos/photo5.png'), dateAdded: '2024-07-05' },
                    { src: require('@/assets/img/photos/photo2.png'), dateAdded: '2024-07-06' }
                ],
            },
            audioFiles: [
                { src: 'https://www.soundhelix.com/examples/mp3/SoundHelix-Song-1.mp3', type: 'audio/mpeg' },
                { src: 'https://www.soundhelix.com/examples/mp3/SoundHelix-Song-2.mp3', type: 'audio/mpeg' }
            ]
        },
        {
            id: 3,
            name: 'Sasha',
            badgeText: 'New',
            description: 'Sasha enjoys spending time in nature and has a passion for photography. She loves capturing beautiful moments with her camera.',
            profileImg: require('@/assets/img/cards/card.png'),
            avatar: require('@/assets/img/cards/avatar.png'),
            attributes: [
                { id: 'ethnicity', value: 'Caucasian', displayName: 'Ethnicity' },
                { id: 'age', value: '28', displayName: 'Age' },
                { id: 'eye', value: 'Blue', displayName: 'Eye Color' },
                { id: 'hairStyle', value: 'Straight', displayName: 'Hair Style' },
                { id: 'hairLength', value: 'Medium', displayName: 'Hair Length' },
                { id: 'hairColor', value: 'Blonde', displayName: 'Hair Color' },
                { id: 'bodyType', value: 'Slim', displayName: 'Body Type' },
                { id: 'breastSize', value: 'Small', displayName: 'Breast Size' },
                { id: 'buttSize', value: 'Small', displayName: 'Butt Size' },
                { id: 'hobbies', value: 'Photography', displayName: 'Hobbies' },
                { id: 'personality', value: 'Adventurous', displayName: 'Personality of your AI' },
                { id: 'occupation', value: 'Photographer', displayName: 'Occupation' }
            ],
            pictures: {
                title: 'Sasha\'s Photos',
                text: 'Sasha enjoys capturing beautiful moments. Check out her photos!',
                images: [
                    { src: require('@/assets/img/photos/photo2.png'), dateAdded: '2024-07-02' },
                    { src: require('@/assets/img/photos/photo1.png'), dateAdded: '2024-07-01' },
                    { src: require('@/assets/img/photos/photo3.png'), dateAdded: '2024-07-03' },
                    { src: require('@/assets/img/photos/photo4.png'), dateAdded: '2024-07-04' },
                    { src: require('@/assets/img/photos/photo5.png'), dateAdded: '2024-07-05' },
                    { src: require('@/assets/img/photos/photo2.png'), dateAdded: '2024-07-06' }
                ],
            },
            audioFiles: [
                { src: 'https://www.soundhelix.com/examples/mp3/SoundHelix-Song-1.mp3', type: 'audio/mpeg' },
                { src: 'https://www.soundhelix.com/examples/mp3/SoundHelix-Song-2.mp3', type: 'audio/mpeg' }
            ]
        },
        {
            id: 4,
            name: 'Nasha',
            badgeText: 'New',
            description: 'Nasha enjoys spending time in nature and has a passion for photography. She loves capturing beautiful moments with her camera.',
            profileImg: require('@/assets/img/cards/card.png'),
            avatar: require('@/assets/img/cards/avatar.png'),
            attributes: [
                { id: 'ethnicity', value: 'Caucasian', displayName: 'Ethnicity' },
                { id: 'age', value: '28', displayName: 'Age' },
                { id: 'eye', value: 'Blue', displayName: 'Eye Color' },
                { id: 'hairStyle', value: 'Straight', displayName: 'Hair Style' },
                { id: 'hairLength', value: 'Medium', displayName: 'Hair Length' },
                { id: 'hairColor', value: 'Blonde', displayName: 'Hair Color' },
                { id: 'bodyType', value: 'Slim', displayName: 'Body Type' },
                { id: 'breastSize', value: 'Small', displayName: 'Breast Size' },
                { id: 'buttSize', value: 'Small', displayName: 'Butt Size' },
                { id: 'hobbies', value: 'Photography', displayName: 'Hobbies' },
                { id: 'personality', value: 'Adventurous', displayName: 'Personality of your AI' },
                { id: 'occupation', value: 'Photographer', displayName: 'Occupation' }
            ],
            pictures: {
                title: 'Nasha\'s Photos',
                text: 'Nasha enjoys capturing beautiful moments. Check out her photos!',
                images: [
                    { src: require('@/assets/img/photos/photo2.png'), dateAdded: '2024-07-02' },
                    { src: require('@/assets/img/photos/photo1.png'), dateAdded: '2024-07-01' },
                    { src: require('@/assets/img/photos/photo3.png'), dateAdded: '2024-07-03' },
                    { src: require('@/assets/img/photos/photo4.png'), dateAdded: '2024-07-04' },
                    { src: require('@/assets/img/photos/photo5.png'), dateAdded: '2024-07-05' },
                    { src: require('@/assets/img/photos/photo2.png'), dateAdded: '2024-07-06' }
                ],
            },
            audioFiles: [
                { src: 'https://www.soundhelix.com/examples/mp3/SoundHelix-Song-1.mp3', type: 'audio/mpeg' },
                { src: 'https://www.soundhelix.com/examples/mp3/SoundHelix-Song-2.mp3', type: 'audio/mpeg' }
            ]
        },
        {
            id: 5,
            name: 'Alasha',
            badgeText: 'New',
            description: 'Alasha enjoys spending time in nature and has a passion for photography. She loves capturing beautiful moments with her camera.',
            profileImg: require('@/assets/img/cards/card.png'),
            avatar: require('@/assets/img/cards/avatar.png'),
            attributes: [
                { id: 'ethnicity', value: 'Caucasian', displayName: 'Ethnicity' },
                { id: 'age', value: '28', displayName: 'Age' },
                { id: 'eye', value: 'Blue', displayName: 'Eye Color' },
                { id: 'hairStyle', value: 'Straight', displayName: 'Hair Style' },
                { id: 'hairLength', value: 'Medium', displayName: 'Hair Length' },
                { id: 'hairColor', value: 'Blonde', displayName: 'Hair Color' },
                { id: 'bodyType', value: 'Slim', displayName: 'Body Type' },
                { id: 'breastSize', value: 'Small', displayName: 'Breast Size' },
                { id: 'buttSize', value: 'Small', displayName: 'Butt Size' },
                { id: 'hobbies', value: 'Photography', displayName: 'Hobbies' },
                { id: 'personality', value: 'Adventurous', displayName: 'Personality of your AI' },
                { id: 'occupation', value: 'Photographer', displayName: 'Occupation' }
            ],
            pictures: {
                title: 'Alasha\'s Photos',
                text: 'Alasha enjoys capturing beautiful moments. Check out her photos!',
                images: [
                    { src: require('@/assets/img/photos/photo2.png'), dateAdded: '2024-07-02' },
                    { src: require('@/assets/img/photos/photo1.png'), dateAdded: '2024-07-01' },
                    { src: require('@/assets/img/photos/photo3.png'), dateAdded: '2024-07-03' },
                    { src: require('@/assets/img/photos/photo4.png'), dateAdded: '2024-07-04' },
                    { src: require('@/assets/img/photos/photo5.png'), dateAdded: '2024-07-05' },
                    { src: require('@/assets/img/photos/photo2.png'), dateAdded: '2024-07-06' }
                ],
            },
            audioFiles: [
                { src: 'https://www.soundhelix.com/examples/mp3/SoundHelix-Song-1.mp3', type: 'audio/mpeg' },
                { src: 'https://www.soundhelix.com/examples/mp3/SoundHelix-Song-2.mp3', type: 'audio/mpeg' }
            ]
        }
    ]
}

export const getFavoriteMembers = async (favoriteMembersId) => {
    const cards = await getCards();
    const favoriteMembers = cards.filter(card => favoriteMembersId.includes(card.id));

    return favoriteMembers;
}

export const getCreatedMembers = async (createdMembersId) => {
    const cards = await getCards();
    const createdMembers = cards.filter(card => createdMembersId.includes(card.id));

    return createdMembers;
}

export const getChatMembers = async (chatMembersId) => {
    const cards = await getCards();
    const chatMembers = cards.filter(card => chatMembersId.includes(card.id));

    return chatMembers;
}

export const banners = {
    homePage: {
        title: 'Create your own AI Girlfriend',
        text: 'Discover your new AI girlfriend! Craft her appearance and personality to your liking, then bring her to life with a single click. Fully realized through the power of artificial intelligence.',
        btnText: '+ Create AI Girlfriend',
        path: '/create',
        img: require('@/assets/img/banners/homePageBanner.png'),
        id: 'createCharacter'
    }
}

const users = [
    { id: 1, email: 'alice@example.com', gender: 'male', firstName: 'Alice', lastName: 'Smith', age: 30, tokens: 100, password: 'alicepass' },
    { id: 2, email: 'bob@example.com', gender: 'male', firstName: 'Bob', lastName: 'Johnson', age: 25, tokens: 150, password: 'bobpass' },
    { id: 3, email: 'charlie@example.com', gender: 'male', firstName: 'Charlie', lastName: 'Williams', age: 35, tokens: 200, password: 'charliepass' },
    {
        id: 3,
        email: 'art@gmail.com',
        firstName: 'Artem',
        lastName: 'Ivo',
        profileImg: require('@/assets/img/photos/profile.png'),
        age: 33,
        tokens: 200,
        password: '12345',
        subscribed: true,
        gender: 'male',
        userPlan : {
            id: 1,
            name: 'Monthly plan',
            price: 20,
            paymentDate: '27.04.24',
            endDate: '27.05.24',
            enabled: true
        },
        favoriteMembers: [1, 2, 3, 4, 5],
        createdMembers: [6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26],
        chatMembers: [1, 2, 3, 4, 5, 6, 7]
    },
];

export const getUsers = async () => {
    await new Promise(resolve => setTimeout(resolve, 1000));
    return users;
};

export const getUserByEmail = async (email) => {
    await new Promise(resolve => setTimeout(resolve, 1000));
    return users.find(user => user.email === email) || null;
};

export const validateUser = async (email, password) => {
    await new Promise(resolve => setTimeout(resolve, 1000));
    const user = users.find(user => user.email === email && user.password === password);
    return user ? { success: true, token: 'fake-jwt-token' } : { success: false };
};
