<template>
    <div class="footer__info">
        <LogoHome/>
        <p class="footer__info-text">AI Romance Chat offers immersive experiences that feel genuine, enabling users to generate images and craft AI characters.</p>
        <p class="footer__info-copyright">© 2024 Airomancechat.com. All Rights Reserved</p>
    </div>
</template>

<script>
import LogoHome from '@/components/components/header/LogoHome.vue';

export default {
    name: 'FooterInfo',
    components: {
        LogoHome
    }
}
</script>
