import { createStore } from 'vuex';
import user from './modules/user';
import chats from './modules/chats';

const store = createStore({
    modules: {
        user, // adds user module
        chats // add chat module
    }
});

export default store;
